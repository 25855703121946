export interface SingleLicenseKey {
  uuid: string;
  licenseKey: string;
  activationDate: string;
  expirationDate: string;
  _links: string[];
}

export interface SingleLicenseEmail {
  uuid: string;
  email: string;
  activatedAt: string;
  createdAt: string;
  expiresAt: string;
  identifier: string;
  serialNumber?: string;
  subscriptionId: string;
  updatedAt: string;
}
export interface LicenseDetails {
  uuid: string;
  subscribedProduct: {
    name: string;
    createdAt: string;
    id: string;
    productType: number;
    updatedAt: string;
    uuid: string;
  };
  subscribedPlan: {
    name: string;
    subscriptionDuration: string;
    createdAt: string;
    foreignPlanId: string;
    id: string;
    shortName: string;
    subscribedProductId: string;
    updatedAt: string;
    planCode: string;
    uuid: string;
  };
  amountOfLicenses: number;
  creationDateTime: string;
  activationDateTime: string;
  expirationDateTime: string;
  licenses: SingleLicenseEmail[] | SingleLicenseKey[];
  isAssisted: boolean;
  isAutoRenewal: boolean;
  isOrderInProgress: boolean;
  isTrial: boolean;
  partner?: Partner;
}

export interface ProductDetails {
  [index: string]: any;
}

export interface SearchedItem {
  value: string;
  type: string;
}
export interface PartnerSubscription {
  activatedAt: string;
  amountOfLicenses: number;
  partnerComment?: string;
  createdAt: string;
  customer: {
    companyName: string;
    addresses: {
      addressLineOne: string;
      addressLineTwo: string;
      attention: string;
      city: string;
      country: string;
      createdAt: string;
      customerId: string;
      customerUUID: string;
      isDefault: boolean;
      phone: string;
      provinceOrState: string;
      type: string;
      updatedAt: string;
      uuid: string;
      zipCode: string;
    };
  };
  customerId: string;
  customerUuid: string;
  expiresAt: string;
  isAssisted: boolean;
  isAutoRenewal: boolean;
  isOrderInProgress: boolean;
  isTrial: boolean;
  licenses: {
    activatedAt: string;
    createdAt: string;
    email: string;
    expiresAt: string;
    identifier: string;
    serialNumber: string;
    subscriptionId: string;
    updatedAt: string;
    uuid: string;
  };
  name: string;
  subscribedPlan: {
    createdAt: string;
    foreignPlanId: string;
    id: string;
    name: string;
    planCode: string;
    shortName: string;
    subscribedProductId: string;
    subscriptionDuration: string;
    updatedAt: string;
    uuid: string;
  };
  subscribedPlanId: string;
  subscribedProduct: {
    createdAt: string;
    id: string;
    name: string;
    productType: string;
    updatedAt: string;
    uuid: string;
  };
  updatedAt: string;
  userProfile: {
    createdAt: string;
    email: string;
    firstName: string;
    language: string;
    lastName: string;
    salutation: string;
    updatedAt: string;
    uuid: string;
  };
  partner: {
    uuid: string;
    name: string;
  };
  uuid: string;
  dialogOpen: boolean;
  cancellationReason?: string;
}
export interface ExportedPartnerSubscription {
  Partner: string;
  Assisted: boolean;
  "Product Key": string;
  "Serial Number": string;
  "Company Name": string;
  Email: string;
  Phone: string;
  Country: string;
  ZipCode: string;
  "Plan Code": string;
  "Activation Date": string;
  "Expiration Date": string;
}

export enum CancellationReason {
  does_not_need_solution = "does_not_need_solution",
  solution_too_expensive = "solution_too_expensive",
  solution_missing_feature = "solution_missing_feature",
  switched_to_another_philips_product = "switched_to_another_philips_product",
  switched_to_competition = "switched_to_competition",
  switched_to_self_service = "switched_to_self_service",
  other_reason = "other_reason"
}
export interface Partner {
  country: string;
  email: string;
  name: string;
  phone: string;
  supportEmail: string;
  supportPhone: string;
  www: string;
}

export interface SearchedSubscription {
  uuid: string;
  customer: {
    email: string;
    companyName: string;
    name: string;
    country: string;
  };
  licenses: {
    identifier: string;
  };
  partnerName?: string;
  isAssisted?: boolean;
}
