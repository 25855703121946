import axios from "axios";
import { apiClient } from "@/plugins/axios";

export default {
  fetchCountries() {
    return apiClient.get("/countries");
  },
  fetchProvinces(country: string) {
    return apiClient.get(`/countries/${country}/provinces`);
  }
};