export interface PaginationResponse {
    currentPage: number;
    from: number;
    lastPage: number;
    perPage: number;
    to: number;
    total: number;
  }
  
  export interface DataTablePagination {
    page: number | undefined;
    itemsPerPage: number | undefined;
    itemsLength: number | undefined;
    pageStart: number | undefined;
    pageStop: number | undefined;
    pageCount: number | undefined;
  }
  
  export function transformPagination(pagination: PaginationResponse) {
    return {
      page: pagination.currentPage,
      itemsPerPage: pagination.perPage,
      pageStart: pagination.currentPage * pagination.perPage - pagination.perPage,
      pageStop: pagination.currentPage * pagination.perPage,
      pageCount: Math.ceil(pagination.total / pagination.perPage),
      itemsLength: pagination.total
    };
  }
  