export const state = {
  type: "info",
  text: "",
  refresh: false,
  show: false,
  logout: false
};

export const mutations = {
  SET_TYPE(state: any, type: string) {
    state.type = type;
  },
  SET_TEXT(state: any, text: string) {
    state.text = text;
  },
  SET_REFRESH(state: any, refresh: string) {
    state.refresh = refresh;
  },
  SET_VISIBILITY(state: any, show: boolean) {
    state.show = show;
  },
  SET_LOGOUT(state: any, logout: boolean) {
    state.logout = logout;
  }
};

export const actions = {
  createNotification(
    context: any,
    data: {
      type: string;
      text: string;
      hasTimeout: boolean;
      timeout?: number;
      refresh?: boolean;
      logout?: boolean;
    }
  ) {
    context.commit("SET_TEXT", data.text);
    context.commit("SET_TYPE", data.type);
    context.commit("SET_REFRESH", data.refresh);
    context.commit("SET_LOGOUT", data.logout);
    context.commit("SET_VISIBILITY", true);
    if (data.hasTimeout) {
      setTimeout(() => {
        context.commit("SET_VISIBILITY", false);
      }, data.timeout || 3000);
    }
  }
};
