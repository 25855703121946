import AuthenticationService from "../services/AuthenticationService";

const rootNavigationItems: any = {
  accountDetails: {
    title: "account_details",
    path: "/account-details"
  },
  accountPartner: {
    title: "account_details",
    path: "/partner-account"
  },
  manageSubscriptions: {
    title: "manage_subscriptions",
    path: "/subscriptions"
  },
  subscriptions: {
    title: "subscriptions",
    path: "/partner-subscriptions"
  },

  orders: {
    title: "orders",
    path: "/partner-orders"
  },
  adminSubscriptionList: {
    title: "subscriptions",
    path: "/admin-subscriptions"
  },
  adminOrderList: {
    title: "orders",
    path: "/admin-orders"
  }
};

const partnerAccountNavigationItems: any = {
  supportInfo: {
    title: "support_info",
    component: "partner-support"
  },
  subpartners: {
    title: "subpartners",
    component: "subpartner-list"
  },
  access: {
    title: "access",
    component: "partner-employees"
  }
};
const partnerSubscriptionsNavigationItems: any = {
  subscriptionList: {
    title: "subscriptions",
    component: "partner-subscriptions"
  },
  createTrial: {
    title: "create_trial",
    component: "speech-live-trial"
  },
  transferSubscriptions: {
    title: "transfer_subscriptions",
    component: "transfer-subscriptions"
  }
};
const partnerOrdersNavigationItems: any = {
  orderList: {
    title: "orders",
    component: "partner-orders"
  },
  tco: {
    title: "add_order",
    component: "add-order",
    externalLink: true,
    action: async () => {
      const response = await AuthenticationService.getUserTcoSso();
      if (response) {
        window.location.href = response.channelManagerUrl;
      }
    }
  },
  commissions: {
    title: "commissions",
    component: "commissions"
  }
};

const filteredPartnerAccountNavigationItems: any[] = [];
const filteredPartnerSubscriptionsNavigationItems: any[] = [];
const filteredPartnerOrdersNavigationItems: any[] = [];

const getRootNavigationItems = (permissions: any): any[] => {
  const filteredRootNavigationItems: any[] = [];

  /* Filter root nav menu items and return the ones that the user scope have access to */
  Object.keys(permissions).forEach((key: string) => {
    if (
      rootNavigationItems.hasOwnProperty(key) &&
      (permissions[key] === true || typeof permissions[key] === "object")
    ) {
      filteredRootNavigationItems.push(rootNavigationItems[key]);
      if (key === "accountPartner") {
        Object.entries(permissions[key]).forEach(([index, value]) => {
          if (
            value &&
            filteredPartnerAccountNavigationItems.indexOf(
              partnerAccountNavigationItems[index]
            ) === -1
          ) {
            filteredPartnerAccountNavigationItems.push(
              partnerAccountNavigationItems[index]
            );
          }
        });
      }
      if (key === "subscriptions") {
        Object.entries(permissions[key]).forEach(([index, value]) => {
          if (
            value &&
            filteredPartnerSubscriptionsNavigationItems.indexOf(
              partnerSubscriptionsNavigationItems[index]
            ) === -1
          ) {
            filteredPartnerSubscriptionsNavigationItems.push(
              partnerSubscriptionsNavigationItems[index]
            );
          }
        });
      }
      if (key === "orders") {
        Object.entries(permissions[key]).forEach(([index, value]) => {
          if (
            value &&
            filteredPartnerOrdersNavigationItems.indexOf(
              partnerOrdersNavigationItems[index]
            ) === -1
          ) {
            filteredPartnerOrdersNavigationItems.push(
              partnerOrdersNavigationItems[index]
            );
          }
        });
      }
    }
  });
  return filteredRootNavigationItems;
};

export {
  rootNavigationItems,
  filteredPartnerAccountNavigationItems,
  filteredPartnerSubscriptionsNavigationItems,
  filteredPartnerOrdersNavigationItems,
  getRootNavigationItems
};
