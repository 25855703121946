






























































































































































































































































































import Vue from "vue";
import NavUserAvatar from "./NavUserAvatar.vue";

import AuthenticationService from "../services/AuthenticationService";
import PhilipsDictation from "../components/_logos/PhilipsDictation.vue";
import CustomerService from "../services/CustomerService";
import getEnv from "../utils/env";
import { getRootNavigationItems } from "../permissions/navigation-permission";

export default Vue.extend({
  name: "NavDrawer",
  props: {
    items: Array
  },
  components: {
    PhilipsDictation,
    NavUserAvatar
  },

  data: () => {
    return {
      miniVariant: true,
      drawer: false,
      drawerHelp: false,
      navItems: [] as any,
      srcHelp: "",
      hasAccessToPurchase: false,
      //{ title: 'Devices', icon: 'mdi-cellphone-link' },
      isLoggedIn: null as boolean | null,
      support: {
        title: "support",
        icon: "mdi-headset",
        path: "/#"
      }
    };
  },
  computed: {
    userInfo(): any {
      return this.$store.getters.userInfo;
    },
    selectedAccount(): any {
      return this.$store.getters.selectedAccount;
    },
    scopePermissions(): any {
      return this.$store.getters.scopePermissions;
    },
    lang(): string {
      return this.$i18n.i18next.language;
    },
    isBuyingEnabled(): any {
      return getEnv("VUE_APP_IS_BUYING_ENABLED");
    }
  },
  created() {
    this.setNavigationItems();
    this.setHelpLink();
  },
  watch: {
    lang(val: string) {
      this.setHelpLink();
    }
  },
  methods: {
    async setNavigationItems() {
      this.navItems = [];

      /* All roles for testing */
      if (this.selectedAccount) {
        const res = await AuthenticationService.getUserPermissions();

        this.navItems = getRootNavigationItems(res.permissions);

        if (this.selectedAccount.roles.includes("speechexec-customer")) {
          this.hasAccessToPurchase = true;
        }
      }
    },
    setHelpLink() {
      let lang = "en";
      if (this.lang.includes("de")) {
        lang = "de";
      } else if (this.lang.includes("fr")) {
        lang = "fr";
      }
      if (
        this.selectedAccount.roles.includes("partner") ||
        this.selectedAccount.roles.includes("sub-partner") ||
        this.selectedAccount.roles.includes("distributor")
      ) {
        this.srcHelp =
          getEnv("VUE_APP_URL_ONLINE_HELP_PARTNER") +
          `?lang=${lang}` +
          getEnv("VUE_APP_UTM_ONLINE_HELP_PARTNER");
      } else if (this.selectedAccount.roles.includes("speechexec-customer")) {
        this.srcHelp =
          getEnv("VUE_APP_URL_ONLINE_HELP_ENDUSER") +
          `?lang=${lang}` +
          getEnv("VUE_APP_UTM_ONLINE_HELP_ENDUSER");
      } else {
        this.srcHelp =
          getEnv("VUE_APP_URL_ONLINE_HELP_SPS_ADMIN") +
          `?lang=${lang}` +
          getEnv("VUE_APP_UTM_ONLINE_HELP_SPS_ADMIN");
      }
    },
    async buySpeechExec() {
      const customerUuid = this.$store.getters.selectedAccount.customerUuid;
      const response = await CustomerService.getSePurchaseLink(
        customerUuid,
        this.lang,
        "se"
      );
      if (response && response.link) {
        window.location = response.link;
      }
    }
  }
});
