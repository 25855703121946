












import Vue from "vue";
import SubscriptionsFilters from "../components/SubscriptionsFilters.vue";
import SubscriptionsTable from "../components/SubscriptionsTable.vue";

export default Vue.extend({
  name: "CustomerSubscription",
  components: {
    SubscriptionsFilters,
    SubscriptionsTable
  }
});
