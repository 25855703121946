




import Vue from "vue";

export default Vue.extend({
  name: "App",
  i18nOptions: {
    namespaces: "common"
  },
  watch: {
    $route() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let windowInstance: any = window;
      windowInstance.Appcues.page();
    }
  },
  data() {
    return {};
  }
});
