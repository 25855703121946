




































import Vue from "vue";
export default Vue.extend({
 name: "Security",
 data: () => ({
  password: "12345678",
  status: "Not Enabled",
  isActive: false,
 }),
});
