




















































































































































































































































































































































































































































































































































































































































































































































import { DataTablePagination, transformPagination } from "../types/pagination";

import Vue from "vue";
import moment from "moment";
import SubscriptionService from "../services/SubscriptionService";
import CountryService from "../services/CountryService";
import { AxiosResponse } from "axios";
import JsonToCsv from "../components/JsonToCsv.vue";
import {
  CancellationReason,
  PartnerSubscription,
  ExportedPartnerSubscription
} from "../types/subscription";
import { SelectItem, DebounceFunction } from "../types/components";
import ChangeCustomer from "../components/_shared/ChangeCustomer.vue";
import AssignPartner from "../components/_admins/AssignPartner.vue";
import ChangeOwner from "../components/_admins/ChangeOwner.vue";
import _ from "lodash";

let getSubscriptionsPromise: Promise<any> | null;
let previousQueryParams: any | null;
let controller = new AbortController();

export default Vue.extend({
  name: "AdminSubscriptions",
  components: {
    JsonToCsv,
    ChangeCustomer,
    AssignPartner,
    ChangeOwner
  },

  data: function() {
    return {
      moment,
      subscriptions: [] as PartnerSubscription[],
      subscriptionsToExport: [] as ExportedPartnerSubscription[],
      allSubscriptionsToExport: [] as ExportedPartnerSubscription[],
      selectedSubscriptions: [] as PartnerSubscription[],
      singleSubscriptionActive: {} as PartnerSubscription,
      loader: null,
      loading: false,
      dialogTrial: false,
      countries: [],
      search: "",
      searchTimerId: 0,
      editEmail: false,
      editEmailOriginal: "",
      editedIndex: -1,
      editedItem: {} as PartnerSubscription,
      loadingExport: false,

      /* Dialogs */
      changeCustomerDialog: false,
      assignPartnerDialog: false,
      changeOwnerDialog: false,

      /* Table headers */
      headers: [] as {
        text: string;
        value: string;
        filter?: any;
        sortable?: boolean;
        align?: string;
        width?: string;
      }[],

      /* Filters */
      date: null as Date | null,
      menu: false,
      date2: null as Date | null,
      menu2: false,
      filterIsAssisted: "all",
      filterSubscriptionStatus: "all",
      subscriptionDateMin: null as Date | string | null,
      subscriptionDateMax: null as Date | string | null,
      filterCancellationReason: undefined as string | undefined,
      searchOptionChosen: "productKey" as string,
      searchOptions: [] as {
        text: string;
        value: string;
      }[],

      // selectedCountry: null as string | null,
      selectedPlan: "",
      plans: [],
      /* Pagination */
      options: {} as any,
      rowsPerPageItems: [2, 5, 10, 25, 50, 100],

      pagination: {
        page: 1,
        itemsPerPage: 10,
        itemsLength: 10,
        pageStart: undefined,
        pageStop: undefined,
        pageCount: undefined
      } as DataTablePagination
    };
  },
  computed: {
    selectedAccount(): any {
      return this.$store.getters.selectedAccount;
    },
    //todo: fill the correct items
    cancellationReasonItems(): SelectItem[] {
      const cancellationReasons = Object.values(CancellationReason);
      let cancellationReasonsObject: SelectItem[] = [];
      cancellationReasons.forEach((key: string) => {
        cancellationReasonsObject.push({ text: this.$t(key), value: key });
      });
      return cancellationReasonsObject;
    },
    cancellationReasonFilterItems(): SelectItem[] {
      return [
        ...this.cancellationReasonItems,
        {
          text: this.$t("unset"),
          value: ""
        }
      ];
    }
  },
  async created() {
    this.headers = [
      {
        text: this.$t("is_self_service"),
        value: "isAssisted",
        width: "100px",
        align: "left",
        sortable: true
      },
      {
        text: this.$t("partner_name"),
        value: "partner.name",
        align: "left",
        sortable: false
      },
      {
        text: this.$t("product_key"),
        value: "licenses.identifier",
        sortable: false
      },
      {
        text: this.$t("customer_name"),
        value: "userProfile.firstName",
        sortable: false
      },
      { text: this.$t("plan"), value: "subscribedPlan.name", sortable: false },
      {
        text: this.$t("expiration_date"),
        value: "expiresAt",
        align: "left",
        width: "200px",
        sortable: true
      },
      {
        text: `${this.$t("actions")}`,
        value: "actions",
        sortable: false
      }
    ];
    //partner_email_product_key_serial_number
    this.searchOptions = [
      {
        text: `${this.$t("product_key")}`,
        value: "productKey"
      },
      {
        text: `${this.$t("partner_name")}`,
        value: "partnerName"
      },
      {
        text: `${this.$t("email")}`,
        value: "customerEmail"
      },
      {
        text: `${this.$t("serial_number")}`,
        value: "serialNumber"
      }
    ];
  },
  methods: {
    debounce(debounceFunction: DebounceFunction, resetPagination = true) {
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        if (resetPagination) this.resetPagination();
        debounceFunction();
      }, 900);
    },
    convertOptions(item: PartnerSubscription) {
      this.editedIndex = this.subscriptions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogTrial = true;
    },

    flattenSubscriptions(
      subscriptions: PartnerSubscription[]
    ): ExportedPartnerSubscription[] {
      // const flattenedSubscriptions = subscriptions.map((sub: any) => {
      //   /* Reassign nested values */
      //   sub.partnerAddressLineOne = sub.partner.addressLineOne;
      //   sub.partnerAddressLineTwo = sub.partner.addressLineTwo;

      //   /* Delete all objects from subscription */
      //   for (const [key, value] of Object.entries(sub)) {
      //     if (typeof sub[key] === "object") delete sub[key];
      //   }
      //   return sub;
      // });
      const subscriptionsList: ExportedPartnerSubscription[] = subscriptions.map(
        (subscription: PartnerSubscription) => {
          return {
            Partner: subscription.partner.name,
            Assisted: subscription.isAssisted,
            "Product Key": subscription.licenses.identifier,
            "Serial Number": subscription.licenses.serialNumber,
            "Company Name": subscription.customer.companyName,
            "Plan Code": subscription.subscribedPlan.planCode,
            "Activation Date": subscription.activatedAt
              ? moment(subscription.activatedAt).format("YYYY-MM-DD HH:mm")
              : "",
            "Expiration Date": subscription.expiresAt
              ? moment(subscription.expiresAt).format("YYYY-MM-DD HH:mm")
              : "",
            "Cancellation reason": subscription.cancellationReason
              ? this.$t(subscription.cancellationReason)
              : "",
            Email: subscription.licenses.email,
            Phone: subscription.customer.addresses.phone,
            FirstName: subscription.userProfile.firstName,
            LastName: subscription.userProfile.lastName,
            AddressLineOne: subscription.customer.addresses.addressLineOne,
            AddressLineTwo: subscription.customer.addresses.addressLineTwo,
            City: subscription.customer.addresses.city,
            ProvinceOrState: subscription.customer.addresses.provinceOrState,
            ZipCode: subscription.customer.addresses.zipCode,
            Country: subscription.customer.addresses.country
          };
        }
      );
      return subscriptionsList;
    },
    async exportCurrentlySelectedSubscriptions() {
      this.subscriptionsToExport = [];
      this.subscriptionsToExport.push(
        ...this.flattenSubscriptions(this.selectedSubscriptions)
      );
      setTimeout(() => {
        const elem: any = this.$refs.exportSubscriptions;
        const result = elem.$el.click();
      }, 200);
    },
    async exportSubscriptions() {
      this.loadingExport = true;

      let subscriptionList = [];
      let queryParams = {
        /* Pagination */
        currentPage: 1,
        perPage: this.pagination.itemsLength || 100000,
        sortBy: undefined,
        sortDesc: undefined,
        isAssisted:
          this.filterIsAssisted === "assisted"
            ? true
            : this.filterIsAssisted === "unassisted"
            ? false
            : undefined,
        subscriptionDateMin: this.subscriptionDateMin
          ? moment(this.subscriptionDateMin)
              .utc()
              .format("YYYY-MM-DD HH:mm")
          : undefined,
        subscriptionDateMax: this.subscriptionDateMax
          ? moment(this.subscriptionDateMax)
              .endOf("day")
              .utc()
              .format("YYYY-MM-DD HH:mm")
          : undefined,
        subscriptionStatus:
          this.filterSubscriptionStatus === "activated"
            ? true
            : this.filterSubscriptionStatus === "not_activated"
            ? false
            : undefined,
        cancellationReason: this.filterCancellationReason
      };

      Object.defineProperty(queryParams, this.searchOptionChosen, {
        value: this.search ? this.search : undefined
      });

      const response = await SubscriptionService.getSubscriptions(queryParams);
      this.loadingExport = false;
      if (response) {
        subscriptionList = response.data;
        /* Set array with reactivity */
        this.subscriptionsToExport = [];
        this.subscriptionsToExport.push(
          ...this.flattenSubscriptions(subscriptionList)
        );
        setTimeout(() => {
          const elem: any = this.$refs.exportSubscriptions;
          const result = elem.$el.click();
        }, 200);
      }
    },
    async exportAllSubscriptions() {
      this.loadingExport = true;
      let allSubscriptions = [];
      const queryParams = {
        /* Pagination */
        currentPage: 1,
        perPage: 100000,
        sortBy: undefined,
        sortDesc: undefined
      };
      const response = await SubscriptionService.getSubscriptions(queryParams);
      this.loadingExport = false;
      if (response) {
        allSubscriptions = response.data;
        /* Set array with reactivity */
        this.allSubscriptionsToExport = [];
        this.allSubscriptionsToExport.push(
          ...this.flattenSubscriptions(allSubscriptions)
        );
        setTimeout(() => {
          const elem: any = this.$refs.exportAll;
          const result = elem.$el.click();
        }, 200);
      }
    },
    async getSubscriptions() {
      this.loading = true;

      let queryParams: any;

      queryParams = {
        /* Pagination */
        currentPage: this.pagination.page || 1,
        perPage: this.pagination.itemsPerPage || 5,
        sortBy: this.options?.sortBy?.[0]
          ? this.options?.sortBy?.[0]
          : undefined,
        sortDesc: this.options?.sortDesc?.[0]
          ? this.options?.sortDesc?.[0]
          : undefined
      };

      queryParams.isAssisted =
        this.filterIsAssisted === "assisted"
          ? true
          : this.filterIsAssisted === "unassisted"
          ? false
          : undefined;
      queryParams.subscriptionStatus =
        this.filterSubscriptionStatus === "activated"
          ? true
          : this.filterSubscriptionStatus === "not_activated"
          ? false
          : undefined;

      queryParams.subscriptionDateMin = this.subscriptionDateMin
        ? moment(this.subscriptionDateMin)
            .utc()
            .format("YYYY-MM-DD HH:mm")
        : undefined;
      queryParams.subscriptionDateMax = this.subscriptionDateMax
        ? moment(this.subscriptionDateMax)
            .endOf("day")
            .utc()
            .format("YYYY-MM-DD HH:mm")
        : undefined;
      queryParams.cancellationReason = this.filterCancellationReason;
      // countryCode: this.selectedCountry ? this.selectedCountry : undefined,
      queryParams[this.searchOptionChosen] = this.search
        ? this.search
        : undefined;

      /* check if the if there is already a request in progress*/
      if (
        !_.isEqual(queryParams, previousQueryParams) ||
        !getSubscriptionsPromise
      ) {
        previousQueryParams = queryParams;

        /* cancel ongoing requestto be able to perform a new one with the new query*/
        if (getSubscriptionsPromise) {
          controller.abort();
          controller = new AbortController();
        }

        getSubscriptionsPromise = SubscriptionService.getSubscriptions(
          queryParams,
          controller
        );
      }

      const response = await getSubscriptionsPromise;
      if (response) {
        previousQueryParams = null;
        getSubscriptionsPromise = null;
        this.pagination = transformPagination(response.pagination);

        this.subscriptions = response.data;
        this.subscriptions.forEach(subscription => {
          this.$set(subscription, "dialogOpen", false);
        });
      }

      this.loading = false;
    },
    async getCountries() {
      const response: AxiosResponse = await CountryService.fetchCountries();
      this.countries = response?.data?.countries;
    },
    async updateEmail(item: any) {
      this.loading = true;

      // const response = await CustomerService.updateEmail(
      //   item?.customer?.uuid,
      //   item?.userProfile?.email
      // );

      // if (!response) {
      //   item.userProfile.email = this.editEmailOriginal;
      // }

      // this.editEmail = false;
      // this.editEmailOriginal = "";
      this.loading = false;
    },
    async manageUsers(item: any) {
      this.loading = true;

      // const response = await CustomerService.getWorkflowRedirectLink(
      //   item.uuid,
      //   item?.customer?.uuid
      // );

      // if (response?.link) {
      //   window.location.href = response.link;
      // }

      this.loading = false;
    },
    closeTrialDialog() {
      this.dialogTrial = false;
    },
    resetPagination() {
      this.pagination.page = 1;
    },
    areSelectedSubscriptionsAreAllUnassisted() {
      let onlyContainsUnassisted = true;

      this.selectedSubscriptions.forEach((sub: PartnerSubscription) => {
        if (sub.isAssisted === true) onlyContainsUnassisted = false;
      });

      return onlyContainsUnassisted;
    }
  }
});
