











































































































































import Vue from "vue";
import { mapState } from "vuex";
import store from "@/store/index";
import { CountryDetails, ProvincesDetails } from "@/types/country";
import { AddressDetails, CustomerStatus } from "@/types/customer";

export default Vue.extend({
 name: "AddressEdit",
 props: {
  name: {
   type: String,
   required: true,
  },
 },

 data: () => ({
  form: null,
  attention: "",
  addressLineOne: "",
  addressLineTwo: "",
  city: "",
  phone: "",
  zipCode: "",
  provinceOrState: "",
  country: "",
  hasProvince: false,
  provincesList: [] as ProvincesDetails[],
 }),
 computed: {
  ...mapState(["customer"]),
  selectedCountry(): string {
   return this.country;
  },
 },
 created() {
  this.customer.customerData.billingAddress = undefined;
 },
 watch: {
  addressLineOne() {
   this.customer.newAddress.addressLineOne = this.addressLineOne;
  },
  addressLineTwo() {
   this.customer.newAddress.addressLineTwo = this.addressLineTwo;
  },
  attention() {
   this.customer.newAddress.attention = this.attention;
  },
  city() {
   this.customer.newAddress.city = this.city;
  },
  phone() {
   this.customer.newAddress.phone = this.phone;
  },
  zipCode() {
   this.customer.newAddress.zipCode = this.zipCode;
  },
  provinceOrState() {
   this.customer.newAddress.provinceOrState = this.provinceOrState;
  },
  country() {
   this.customer.newAddress.country = this.country;
   this.getProvincesList(this.country);
  },
  selectedCountry() {
   this.hasProvince = false;
   this.provinceOrState = "";
   this.getProvincesList(this.selectedCountry);
  },
 },

 methods: {
  setProvince() {
   if (this.provinceOrState) {
    this.hasProvince = true;
    this.getProvincesList(this.country);
   } else {
    this.hasProvince = false;
   }
  },
  getProvincesList(country: string) {
   this.customer.countriesList.forEach((element: CountryDetails) => {
    if (element.name === country && element.hasProvinces) {
     this.hasProvince = true;
     this.provincesList = element.provincesList;
    }
   });
  },
 },
});
