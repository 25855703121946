import VueRouter, { Route, NavigationGuardNext, RawLocation } from "vue-router";
import {
  msalInstance,
  ExtendedPublicClientApplication
} from "../plugins/msalPlugin";

const removeHash = (to: Route, router: VueRouter) => {
  if (to.hash && to.hash.includes("#state=")) {
    const newLocation: RawLocation = {
      path: to.path,
      hash: "",
      query: to.query,
      params: to.params
    };
    router.push(newLocation);
  }
};

const registerGuard = (router: VueRouter) => {
  router.beforeEach(
    async (to: Route, from: Route, next: NavigationGuardNext) => {
      if (to.meta?.requiresAuth) {
        await isAuthenticated(msalInstance, next);
        removeHash(to, router);
      }

      next();
    }
  );
};

const isAuthenticated = async (
  instance: ExtendedPublicClientApplication,
  next: NavigationGuardNext
): Promise<boolean> => {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
  return instance
    .handleRedirectPromise()
    .then(() => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        return true;
      }

      return instance
        .authenticateRedirect()
        .then(() => {
          next();
          return true;
        })
        .catch(() => {
          return false;
        });
    })
    .catch(() => {
      return false;
    });
};

const isSpeechExecCustomerGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const customer = msalInstance?.data?.custom?.selectedAccount;
  if (customer && customer.roles.includes("speechexec-customer")) {
    next();
  } else {
    next("/404");
  }
};

const isAnyPartnerGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const partner = msalInstance?.data?.custom?.selectedAccount;
  if (
    partner &&
    (partner.roles.includes("distributor") ||
      partner.roles.includes("partner") ||
      partner.roles.includes("sub-partner"))
  ) {
    next();
  } else {
    next("/404");
  }
};

const isDistributorGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const partner = msalInstance?.data?.custom?.selectedAccount;
  if (partner && partner.roles.includes("distributor")) {
    next();
  } else {
    next("/404");
  }
};

const isAdminOrSupportGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const admin = msalInstance?.data?.custom?.selectedAccount;
  if (
    admin &&
    (admin.roles.includes("global-admin") ||
      admin.roles.includes("regional-admin") ||
      admin.roles.includes("global-support") ||
      admin.roles.includes("regional-support"))
  ) {
    next();
  } else {
    next("/404");
  }
};

const isGlobalAdminGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const admin = msalInstance?.data?.custom?.selectedAccount;
  if (admin && admin.roles.includes("global-admin")) {
    next();
  } else {
    next("/404");
  }
};

const isRegionalAdminGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const admin = msalInstance?.data?.custom?.selectedAccount;
  if (admin && admin.roles.includes("regional-admin")) {
    next();
  } else {
    next("/404");
  }
};

const isGlobalSupportGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const admin = msalInstance?.data?.custom?.selectedAccount;
  if (admin && admin.roles.includes("global-support")) {
    next();
  } else {
    next("/404");
  }
};

const isRegionalSupportGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const admin = msalInstance?.data?.custom?.selectedAccount;
  if (admin && admin.roles.includes("regional-support")) {
    next();
  } else {
    next("/404");
  }
};

export {
  registerGuard,
  isSpeechExecCustomerGuard,
  isAnyPartnerGuard,
  isDistributorGuard,
  isAdminOrSupportGuard,
  isGlobalAdminGuard,
  isRegionalAdminGuard,
  isGlobalSupportGuard,
  isRegionalSupportGuard
};
