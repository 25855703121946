interface ProcessEnv {
  [key: string]: string | undefined | any | {};
}

declare global {
  interface Window {
    CONFIG: any;
  }
}

export default function getEnv(name: string): ProcessEnv {
  return window?.CONFIG?.[name] || process.env[name];
}
