































































import Vue from "vue";
import { mapState } from "vuex";
import NavDrawer from "../components/NavDrawer.vue";
import PhilipsDictation from "../components/_logos/PhilipsDictation.vue";
import { setScopeHeader } from "../plugins/axios";

export default Vue.extend({
  name: "GeneralLayout",
  components: {
    NavDrawer,
    PhilipsDictation
  },

  data: () => {
    return {
      showLoading: true
    };
  },

  computed: {
    ...mapState(["notification"]),

    userInfo(): any {
      return this.$store.getters.userInfo;
    },
    selectedAccount(): any {
      return this.$store.getters.selectedAccount;
    },
    hasAuthenticationErrors(): boolean {
      return this.$store.getters.hasAuthenticationErrors;
    }
  },
  async created() {
    await this.authenticateUser();
  },
  methods: {
    reload() {
      location.reload();
    },
    logout() {
      this.$msal.logout();
    },
    async authenticateUser() {
      if (!this.selectedAccount) {
        this.$msal.authenticateUser();
      } else {
        setScopeHeader(this.selectedAccount.roles[0]);
      }
    }
  }
});
