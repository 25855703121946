






















import Vue from "vue";
import { UserIdentity } from "../types/account";

export default Vue.extend({
  name: "HomeDashboard",
  data: () => {
    return {
      language: "en" as string
    };
  },
  computed: {
    selectedAccount(): any {
      return this.$store.getters.selectedAccount;
    },
    userName(): string {
      return `${this.selectedAccount.firstName} ${this.selectedAccount.lastName}`;
    },
    greeting() {
      let greetDate = new Date();
      let hours = greetDate.getHours();

      if (hours >= 5 && hours <= 11) {
        return this.$t("morning");
      } else if (hours >= 12 && hours <= 17) {
        return "afternoon";
      } else if (hours >= 18 && hours <= 21) {
        return "evening";
      } else if (hours >= 22 && hours <= 4) {
        return "night";
      } else {
        return "hello";
      }
    }
  },
  async created() {
    const userIdentity: UserIdentity = await this.getUserIdentity();

    /* Initialize user identity */
    if (this.selectedAccount && this.selectedAccount.uuid) {
      this.getLanguage();
      await this.$analytics.identify(userIdentity);
      await this.identifyAppcuesUser(userIdentity);
      /* Track login */
      await this.$analytics.track("userLoggedIn");
    }
  },

  methods: {
    getLanguage() {
      let detectedLanguage = this.$i18n.i18next.language;
      switch (detectedLanguage) {
        case "de": {
          this.language = "de";
          break;
        }
        case "fr": {
          this.language = "fr";
          break;
        }
        default: {
          this.language = "en";
          break;
        }
      }
    },
    async getUserIdentity(): Promise<UserIdentity> {
      const azureAccount = await this.$msal.getUserAccount();

      const userData: UserIdentity = {
        userId: azureAccount.localAccountId,
        name: `${this.selectedAccount.firstName} ${this.selectedAccount.lastName}`,
        email: this.selectedAccount.email || this.selectedAccount.eMail,
        companyName:
          this.selectedAccount.companyName || this.selectedAccount.partner.name,
        speechIdUser: true,
        speechIdRoles: this.selectedAccount.roles[0],
        country:
          this.selectedAccount.country || this.selectedAccount.partner.country,
        accountLanguage:
          this.language ||
          this.selectedAccount.language ||
          this.selectedAccount.partner.language,
        industry: this.selectedAccount.industry
      };

      return userData;
    },
    async identifyAppcuesUser(userIdentity: UserIdentity) {
      (window as any)?.Appcues?.identify(userIdentity);
    }
  }
});
