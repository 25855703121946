









import Vue from "vue";

export default Vue.extend({
  name: "Logout",
  created() {
    this.$msal.logout();
  }
});
