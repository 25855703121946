import * as lpTypes from "@/types/subscription";
import { AxiosError } from "@/types/error";

export const state = {
  subscriptions: [] as lpTypes.LicenseDetails[],
  productsList: [] as string[],
  plansList: [] as lpTypes.ProductDetails,
  chosenPlan: "" as string,
  chosenProject: "" as string,
  sortBy: "" as string,
  subscriptionDateMin: null as string | null,
  subscriptionDateMax: null as string | null,
  notificationMessage: "" as string,
  notificationType: "" as string
};


const getters = {
  subscriptions: (state: any) => state.subscriptions,
  chosenPlan: (state: any) => state.chosenPlan,
  chosenProject: (state: any) => state.chosenProject,
  sortBy: (state: any) => state.sortBy,
  subscriptionDateMin: (state: any) => state.subscriptionDateMin,
  subscriptionDateMax: (state: any) => state.subscriptionDateMax,
};

const mutations = {
  SET_DATA(state: any, subscriptions: lpTypes.LicenseDetails[]) {
    state.subscriptions = subscriptions;
  },
  SET_FILTERS(state: any) {
    for (let item of state.subscriptions) {
      state.productsList.indexOf(item.subscribedProduct.name) === -1 &&
        state.productsList.push(item.subscribedProduct.name);
      state.plansList.indexOf(item.subscribedPlan.planCode) === -1 &&
        state.plansList.push(item.subscribedPlan.planCode);
    }
  },
  SET_CHOSEN_PLAN(state: any, item: string) {
    state.chosenPlan = item;
  },
  SET_CHOSEN_PROJECT(state: any, item: string) {
    state.chosenProject = item;
  },
  SET_SORT_BY(state: any, item: string) {
    state.sortBy = item;
  },
  SET_NOTIFICATION(state: any, item: AxiosError) {
    state.notificationMessage = item.message;
    state.notificationType = item.name;
  }
};

const actions = {
  setSubscriptionsData(context: any, data: any) {
    context.commit("SET_DATA", data);
  },

  fetchFilters(context: any) {
    return context.commit("SET_FILTERS");
  },
  setChosenPlan(context: any, item: string) {
    return context.commit("SET_CHOSEN_PLAN", item);
  },
  setChosenProject(context: any, item: string) {
    return context.commit("SET_CHOSEN_PROJECT", item);
  },
  setSortBy(context: any, item: string) {
    return context.commit("SET_SORT_BY", item);
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};