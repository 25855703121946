export interface AddressDetails {
  type: number;
  attention: string;
  isDefault: boolean;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  provinceOrState: string;
  zipCode: string;
  country: string;
  phone: string;
}

export interface CustomerDetails {
  uuid: string;
  companyName: string;
  defaultRegion: string;
  type: string;
  isDemo: boolean;
  industry: string;
  taxId: string;
  addresses: AddressDetails[];
  shippingAddress: AddressDetails;
  billingAddress: AddressDetails;
  status: string;
  createdAt: Date;
  updatedAt: Date;
}
export enum CustomerStatus {
  PENDING = 0,
  ACTIVE = 1,
  SUSPENDED = 2,
  EXPIRED = 3,
  DELETED = 4
}

export enum Industry {
  Other = 0,
  Construction = 1,
  Education = 2,
  FinancialServices = 3,
  Government = 4,
  Healthcare = 5,
  Insurance = 6,
  Legal = 7,
  PublicSector = 8,
  RealEstate = 9
}

export interface SpeechProfile {
  sapId?: string;
  language: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  addressLineOne: string;
  country: string;
  countryCode: string;
  city: string;
  stateProvince?: string;
  stateProvinceCode?: string;
  zipCode: string;
  phoneNumber: string;
  companyName: string;
  isConsentEnabled: boolean;
  industry?: string;
  jobPosition?: string;
}
export interface SpeechProfileTransfer {
  subscriptionUuid: any;
  oldCustomerUuid: string;
  customerUuid: string;
  customerEmail: string;
  productKey: string;
  oldCustomerEmail: string;
}
