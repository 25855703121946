import getEnv from "./utils/env";

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: getEnv("VUE_APP_AZURE_B2C_CLIENT_ID").toString(),
    //clientSecret: "lki8Q~ksBa~8xjgO4S1yW5EjYEGW04oMOTpDUaU5",
    authority: `https://${getEnv(
      "VUE_APP_AZURE_B2C_TENANT"
    )}.b2clogin.com/${getEnv(
      "VUE_APP_AZURE_B2C_TENANT"
    )}.onmicrosoft.com/${getEnv("VUE_APP_AZURE_B2C_POLICY_LOGIN")}`,
    knownAuthorities: [
      `https://${getEnv("VUE_APP_AZURE_B2C_TENANT")}.b2clogin.com/${getEnv(
        "VUE_APP_AZURE_B2C_TENANT"
      )}.onmicrosoft.com/${getEnv("VUE_APP_AZURE_B2C_POLICY_LOGIN")}`,
      `https://${getEnv("VUE_APP_AZURE_B2C_TENANT")}.b2clogin.com/${getEnv(
        "VUE_APP_AZURE_B2C_TENANT"
      )}.onmicrosoft.com/${getEnv("VUE_APP_AZURE_B2C_POLICY_REGISTER")}`
    ],
    redirectUri: getEnv("VUE_APP_AZURE_B2C_REDIRECT_URI").toString(),
    postLogoutRedirectUri: getEnv("VUE_APP_AZURE_B2C_REDIRECT_URI").toString(),
    scopes: [
      `https://${getEnv("VUE_APP_AZURE_B2C_TENANT")}/${getEnv(
        "VUE_APP_AZURE_B2C_CLIENT_ID"
      )}/read`
    ],
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "sessionStorage"
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [
    `https://${getEnv("VUE_APP_AZURE_B2C_TENANT")}/${getEnv(
      "VUE_APP_AZURE_B2C_CLIENT_ID"
    )}/read`
  ]
};
