import type {Partner, Customer } from "../../types/account"
interface Account {
  name: string;
  sid: string;
  userName: string;
}

const state = {
  hasAuthenticationErrors: false,
  isAccountSelected: false,
  userInfo: null as Partner | Customer | null,
  hasAccountSelector: false,
  selectedAccount: null,
  responseError: "",
  hasError: false,
  token: "",
  loading: false,
  responseDetails: "",
  /*   emails: "",
    name: "", */
  selectedScope: "",
  scopePermissions: []
};

const getters = {
  hasAuthenticationErrors: (state: any) => state.hasAuthenticationErrors,
  userInfo: (state: any) => state.userInfo,
  isAccountSelected: (state: any) => state.accountSelected,
  hasAccountSelector: (state: any) => state.hasAccountSelector,
  selectedAccount: (state: any) => state.selectedAccount,
  selectedScope: (state: any) => state.selectedScope,
  scopePermissions: (state: any) => state.scopePermissions
};
const actions = {
  setAuthenticationErrors(context: any, value: boolean) {
    context.commit("SET_AUTHENTICATION_ERRORS", value);
  },
  setUserInfo(context: any, data: Account) {
    context.commit("SET_USER_INFO", data);
  },
  setIsAccountSelected(context: any, isSelected: boolean) {
    context.commit("SET_IS_SELECTED_ACCOUNT", isSelected);
  },
  requiresAccountSelector(context: any, hasAccountSelector: boolean) {
    context.commit("SET_HAS_ACCOUNT_SELECTOR", hasAccountSelector);
  },
  setSelectedAccount(context: any, data: any) {
    context.commit("SET_SELECTED_ACCOUNT", data);
  },
  setSelectedScope(context: any, data: any) {
    context.commit("SET_SELECTED_SCOPE", data);
  },
  setScopePermissions(context: any, data: any) {
    context.commit("SET_SCOPE_PERMISSIONS", data);
  }
};

const mutations = {
  SET_ERROR(state: any, errorMessage: string) {
    state.responseError = errorMessage;
    state.hasError = true;
  },
  SET_USER_INFO(state: any, data: Account) {
    state.userInfo = data;
  },
  SET_LOADING(state: any, loading: boolean) {
    state.loading = loading;
  },
  SET_DETAILS(state: any, data: Record<string, any>) {
    state.responseDetails = data;
  },
  SET_AUTHENTICATION_ERRORS(state: any, value: boolean) {
    state.hasAuthenticationErrors = value;
  },
  SET_IS_SELECTED_ACCOUNT(state: any, isSelected: boolean) {
    state.isAccountSelected = isSelected;
  },
  SET_HAS_ACCOUNT_SELECTOR(state: any, hasAccountSelector: boolean) {
    state.hasAccountSelector = hasAccountSelector;
  },
  SET_SELECTED_ACCOUNT(state: any, data: any) {
    state.selectedAccount = data;
  },
  SET_SELECTED_SCOPE(state: any, data: any) {
    state.selectedScope = data;
  },
  SET_SCOPE_PERMISSIONS(state: any, data: any) {
    state.scopePermissions = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
