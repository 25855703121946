import type { AxiosError } from "axios";
import { apiClient } from "@/plugins/axios";
import store from '@/store';
import { msalInstance } from "@/plugins/msalPlugin";
import type { SpeechProfile} from '../types/customer';
import { RefundObject } from "@/types/order";

export default {
  async fetchCustomerData() {
    const customerUUID = store.getters.selectedAccount.customerUuid;

    const token = await msalInstance.getToken();;
    const response = await apiClient.get(`/customers/${customerUUID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;

  },
  async getOrdersForCustomer(customerUuid: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/customers/${customerUuid}/orders`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data.orders;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async getInvoiceForOrder(customerUuid: string, orderUuid: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/customers/${customerUuid}/orders/${orderUuid}/invoice`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
      }
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      if (error.message.includes("400")) {
        store.dispatch("createNotification", {
          type: "warning",
          text: "An invoice was not found for this order",
          hasTimeout: true,
          timeout: 10000
        })
      } else {
        await error?.globalHandler();
      }
    })
    return response;
  },
  async getInvoiceForRefundOrder(customerUuid: string, orderUuid: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/customers/${customerUuid}/orders/${orderUuid}/refund-invoice`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
      }
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      if (error.message.includes("400")) {
        store.dispatch("createNotification", {
          type: "warning",
          text: "An invoice was not found for this order",
          hasTimeout: true,
          timeout: 10000
        })
      } else {
        await error?.globalHandler();
      }
    })
    return response;
  },
  async sendRefundRequest(data: RefundObject) {
    const token = await msalInstance.getToken();
    const response = await apiClient.post(`/customers/${data.customerUuid}/orders/${data.orderUuid}/refund`, {
      reason: data.reason,
      comment: data.comment
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async getPaymentMethodsLink(customerUuid: string, language: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/customers/${customerUuid}/payment-methods-link`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept-Language': language
      }
    }).then((responseApi: any) => {
      return responseApi.data.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async getSePurchaseLink(customerUuid: string, language: string, product: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/customers/${customerUuid}/subscriptions/purchase-link`, {
      params: {
        productCode: product
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept-Language': language
      }
    }).then((responseApi: any) => {
      return responseApi.data.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async getRenewalLinkForSubscription(customerUuid: string, subscriptionUuid: string, language: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/customers/${customerUuid}/subscriptions/${subscriptionUuid}/renewal-link`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept-Language': language
      }
    }).then((responseApi: any) => {
      return responseApi.data.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async getUpgradeLinkForSubscription(customerUuid: string, subscriptionUuid: string, language: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/customers/${customerUuid}/subscriptions/${subscriptionUuid}/upgrade-link`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept-Language': language
      }
    }).then((responseApi: any) => {
      return responseApi.data.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async transferSubscription(customerUuid: string, subscriptionUuid: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.post(`/customers/${customerUuid}/subscriptions/${subscriptionUuid}/take-over`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    }).then((responseApi: any) => {
      return true;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async disableAutoRenewalForSubscription(customerUuid: string, subscriptionUuid: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.post(`/customers/${customerUuid}/subscriptions/${subscriptionUuid}/disable-auto-renewal`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    }).then((responseApi: any) => {
      return true;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async getCustomerByEmail(email: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/customers?email=${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async createSpeechProfile(data: SpeechProfile) {
    try {
      const response = await apiClient.post(`/customers/speechexec/register`, data);
      return response.data.data;
    } catch (err: any) {
      await err?.globalHandler();
    }
  },

  /* async updateAddress(addressUuid: string, data: object) {
    const customerUUID = msal.$msal.data.custom.selectedAccount.customerUuid;
    if (!customerUUID) {
      router.push("account-selector");
    }

    const token = await msalInstance.getToken();;
    if (!token) {
      msal.$msal.signIn();
    }
    return await apiClient.post(
      `/customers/${customerUUID}/addresses/${addressUuid}`,
      data,
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    );
  },
  async createAddress(data: object) {
    const customerUUID = msal.$msal.data.custom.selectedAccount.customerUuid;
    if (!customerUUID) {
      router.push("account-selector");
    }

    const token = await msalInstance.getToken();;
    if (!token) {
      msal.$msal.signIn();
    }

    return await apiClient.post(`/customers/${customerUUID}/addresses`, data, {
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  successRegistration(customerUUID: string) {
    return apiClient.post(
      `/customers/${customerUUID}/subscriptions/speechlive/trial`
    );
  },
  fetchSpeechLiveCountries() {
    return apiClient.get("/products/speechlive/countries");
  },
   */
};
