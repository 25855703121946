










































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import store from "@/store/index";
import AddressDisplay from "@/components/_shared/_Address.vue";
import AddressEdit from "@/components/_shared/_AddressEdit.vue";
import AddressCreate from "@/components/_shared/_AddressCreate.vue";
import { CountryDetails, ProvincesDetails } from "@/types/country";
import { AddressDetails, CustomerStatus, Industry } from "@/types/customer";

export default Vue.extend({
 name: "Account",
 components: {
  AddressDisplay,
  AddressEdit,
  AddressCreate,
 },
 data: () => ({
  addBillingAddress: false,
  editable: false,
  addressName: "Main address",
  editAddress: false,
  loadingData: false,
  provincesList: [] as ProvincesDetails[],
 }),
 computed: {
  ...mapState(["customer"]),
  billingAddress(): AddressDetails {
   return this.customer.customerData.billingAddress;
  },
 },
 async created() {
  await this.retrieveData();
 },

 methods: {
  async retrieveData() {
   this.loadingData = true;
   //await store.dispatch("fetchCountries");
   await this.$store.dispatch("fetchCustomerData");
   this.loadingData = false;
  },
  returnStatus(number: number) {
   return CustomerStatus[number];
  },
  returnIndustry(number: number) {
   return Industry[number];
  },
  /* async updateAddress(addressId: string) {
   const data = {
    type: 0,
    attention: this.billingAddress.attention,
    isDefault: true,
    addressLineOne: this.billingAddress.addressLineOne,
    addressLineTwo: this.billingAddress.addressLineTwo,
    city: this.billingAddress.city,
    provinceOrState: this.billingAddress.provinceOrState,
    zipCode: this.billingAddress.zipCode,
    country: this.billingAddress.country,
    phone: this.billingAddress.phone,
   };
   if (!this.equalObjects(data, this.customer.savedAddress)) {
    const response = await store.dispatch("updateAddress", {
     addressId,
     data,
    });

    switch (response.statusText) {
     case "OK":
      console.log(response);
     default:
      this.discardAddress();
    }
   }
   this.editAddress = false;
  },
  async createAddress() {
   const data = {
    type: 0,
    attention: this.customer.newAddress.attention,
    isDefault: true,
    addressLineOne: this.customer.newAddress.addressLineOne,
    addressLineTwo: this.customer.newAddress.addressLineTwo,
    city: this.customer.newAddress.city,
    provinceOrState: this.customer.newAddress.provinceOrState,
    zipCode: this.customer.newAddress.zipCode,
    country: this.customer.newAddress.country,
    phone: this.customer.newAddress.phone,
   };
   const response = await store.dispatch("createAddress", data);
   switch (response.statusText) {
    case "OK":
     this.customer.newAddress = {};
     this.customer.customerData.billingAddress = data;
    default:
     this.customer.newAddress = {};
   }
   this.addBillingAddress = false;
  },
  discardAddress() {
   this.billingAddress = this.customer.customerData.billingAddress;
   this.editAddress = false;
  },
  equalObjects(o1: any, o2: any) {
   for (var p in o1) {
    if (o1.hasOwnProperty(p)) {
     if (o1[p] !== o2[p]) {
      return false;
     }
    }
   }
   for (var p in o2) {
    if (o2.hasOwnProperty(p)) {
     if (o1[p] !== o2[p]) {
      return false;
     }
    }
   }
   return true;
  }, */
 },
});
