




































































import Vue from "vue";
import SubscriptionService from "../../services/SubscriptionService";
import type {
 SearchedSubscription,
} from "../../types/subscription";
import type {
 Partner,
} from "../../types/partner";

export default Vue.extend({
 name: "AssignPartner",
  props: {
    selectedSubscriptions: { type: Array, default: () => [] },
  },
 data() {
  return {
   selected: [],
   partner: null as Partner | null,
   nullPartner: false,
   sapId: "",
   productKey: "",
   email: "",
   companyName: "",
   partnerName: "",
   country: "",
   zipCode: "",
   loading: false,
   items: [] as SearchedSubscription[],
   loadSearch: false,
   loadAssign: false,
  };
 },
 methods: {
  async searchPartner() {
   this.loadSearch = true;
   const response = await SubscriptionService.retrievePartner(this.sapId, true);
   if (response) {
    this.partner = response;
   }
   this.loadSearch = false;
  },
  async assignSelectedSubscriptions() {
   this.loadAssign = true;
   if (this.partner) {
    await SubscriptionService.assignPartner(
     this.partner.uuid,
     this.$props.selectedSubscriptions.map((x: SearchedSubscription) => x.uuid)
    );
    this.$analytics.track('partnerAssigned', {
        noOfLicenses: this.selectedSubscriptions.length
      })
   }
   this.loadAssign = false;
   this.$store.dispatch("createNotification", {
     type: "success",
     text: this.$t("commission_assigned_to_partner"),
     hasTimeout: true
   });
   this.$emit("closeDialog");
  },
 },
});
