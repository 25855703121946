


























































































































































































import Vue from "vue";
import PartnerService from "../services/PartnerService";
import JsonToCsv from "../components/JsonToCsv.vue";
import moment from "moment";

interface OrderCommission {
  purchaseDate: Date;
  companyName: string;
  customerName: string;
  materialNumber: string;
  subscriptionType: string;
  purchasingAmount: number;
  commission: number;
  currency: string;
  exchangeRate: number;
  payoutCurrency: string;
  purchasingAmountInPayoutCurrency: number;
  serialNumber: string;
  identifier: string;
  createdAt: Date;
  updatedAt: Date;
  commissionStatus: string;
  commissionAmountInPayoutCurrency: number;
  commissionMonth: string;
  country: null | string;
  region: null | string;
  sapSalesDocument: null | string;
  uuid: string;
}

interface OrderCommissionExport {
  purchaseDate: Date;
  companyName: string;
  customerName: string;
  materialNumber: string;
  serialNumber: string;
  identifier: string;
  subscriptionType: string;
  sapSalesDocument: null | string;
  commission: number;
  commissionMonth: string;
  commissionStatus: string;
  commissionAmountInPayoutCurrency: number;
  purchasingAmount: number;
  currency: string;
  exchangeRate: number;
  purchasingAmountInPayoutCurrency: number;
  payoutCurrency: string;
}
export default Vue.extend({
  name: "OrderCommissions",
  components: {
    JsonToCsv
  },
  data: () => {
    return {
      moment,
      search: "",
      searchTimerId: null,
      loader: null,
      tab: null,
      loading: false,
      loadTransferButton: false,
      disabledUpload: true,
      loadingUpload: false,
      disabledTransfer: true,
      file: null,
      orderCommissions: [] as OrderCommission[],
      orderCommissionsExport: [] as OrderCommissionExport[],
      headers: [] as {
        text: string;
        value: string;
        filter?: any;
        sortable?: boolean;
        align?: string;
      }[]
    };
  },
  async created() {
    this.headers = [
      { text: this.$t("purchase_date"), value: "purchaseDate" },
      { text: this.$t("customer_name"), value: "customerName" },
      { text: this.$t("product_key_serial_number"), value: "serialNumber" },
      { text: this.$t("order_type"), value: "subscriptionType" },
      { text: this.$t("invoice_order_no"), value: "sapSalesDocument" },
      { text: this.$t("commission_payout_date"), value: "commissionMonth" },
      {
        text: this.$t("commission_amount_in_payout_currency"),
        value: "commissionAmountInPayoutCurrency"
      },
      {
        text: `${this.$t("status")}`,
        value: "commissionStatus",
        align: "right"
      }
    ];
    await this.getCommissionList();
  },

  methods: {
    getPartnerUuid(): string {
      return this.$store.getters.selectedAccount.partner.uuid;
    },
    customFilter(value: any, search: string | null, item: any) {
      if (!search) {
        return true;
      }
      const regex = new RegExp(search.toLowerCase(), "g");

      for (const key in item) {
        if (
          (typeof item[key] === "string" || typeof item[key] === "number") &&
          item[key]
            ?.toString()
            ?.toLowerCase()
            ?.match(regex)
        ) {
          return true;
        }
      }

      return false;
    },
    async getCommissionList() {
      this.loading = true;
      const partnerUuid = this.getPartnerUuid();
      this.orderCommissions = await PartnerService.getOrderCommissionsList(
        partnerUuid
      );
      let commissionsToExport: OrderCommissionExport[] = [];
      this.orderCommissions.forEach(commission => {
        commissionsToExport.push({
          purchaseDate: commission.purchaseDate,
          companyName: commission.companyName,
          customerName: commission.customerName,
          materialNumber: commission.materialNumber,
          serialNumber: commission.serialNumber,
          identifier: commission.identifier,
          subscriptionType: commission.subscriptionType,
          sapSalesDocument: commission.sapSalesDocument,

          commission: commission.commission,
          commissionMonth: moment(commission.commissionMonth)
            .set("date", 15)
            .add(2, "M")
            .format("YYYY-MM-DD"),
          commissionStatus: commission.commissionStatus,
          purchasingAmount: commission.purchasingAmount,
          currency: commission.currency,
          exchangeRate: commission.exchangeRate,
          purchasingAmountInPayoutCurrency:
            commission.purchasingAmountInPayoutCurrency,
          commissionAmountInPayoutCurrency:
            commission.commissionAmountInPayoutCurrency,
          payoutCurrency: commission.payoutCurrency
        });
      });
      this.orderCommissionsExport = commissionsToExport;
      this.loading = false;
    }
  }
});
