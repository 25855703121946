import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import GeneralLayout from "../layouts/GeneralLayout.vue";
import PersonalSettings from "../views/PersonalSettings.vue";
import Logout from "../views/Logout.vue";
import NotFound from "../views/NotFound.vue";
import AccountSelector from "../views/AccountSelector.vue";
import SimpleLayout from "../layouts/SimpleLayout.vue";
import Subscriptions from "../views/Subscriptions.vue";
import AccountDetails from "../views/AccountDetails.vue";
import AdminSubscriptions from "../views/AdminSubscriptions.vue";
import AdminOrders from "../views/AdminOrders.vue";
import {
  registerGuard,
  isSpeechExecCustomerGuard,
  isAnyPartnerGuard,
  isDistributorGuard,
  isAdminOrSupportGuard
} from "./guards";
import SpeechExecPurchase from "../views/SpeechExecPurchase.vue";
import PartnerAccount from "../views/PartnerAccount.vue";
import PartnerOrders from "../views/PartnerOrders.vue";
import PartnerSubscriptions from "../views/PartnerSubscriptions.vue";
import SetPassword from "../views/SetPassword.vue";
import ActivateAccount from "../views/ActivateAccount.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/account-selector",
    component: SimpleLayout,
    children: [
      {
        path: "",
        name: "account-selector",
        component: AccountSelector,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/",
    component: GeneralLayout,
    children: [
      {
        path: "",
        name: "home",
        component: Home,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/personal-settings",
        name: "personal-settings",
        component: PersonalSettings,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/account-details",
        name: "account-details",
        component: AccountDetails,
        meta: {
          requiresAuth: true
        },
        beforeEnter: isSpeechExecCustomerGuard
      },
      {
        path: "/subscriptions",
        name: "subscriptions",
        component: Subscriptions,
        meta: {
          requiresAuth: true
        },
        beforeEnter: isSpeechExecCustomerGuard
      },
      {
        path: "/partner-account",
        name: "partner-account",
        component: PartnerAccount,
        meta: {
          requiresAuth: true
        },
        beforeEnter: isAnyPartnerGuard
      },
      {
        path: "/partner-subscriptions",
        name: "partner-subscriptions",
        component: PartnerSubscriptions,
        meta: {
          requiresAuth: true
        },
        beforeEnter: isAnyPartnerGuard
      },
      {
        path: "/partner-orders",
        name: "partner-orders",
        component: PartnerOrders,
        meta: {
          requiresAuth: true
        },
        beforeEnter: isAnyPartnerGuard
      },
      {
        path: "/admin-orders",
        name: "admin-orders",
        component: AdminOrders,
        beforeEnter: isAdminOrSupportGuard
      },
      {
        path: "/admin-subscriptions",
        name: "admin-subscriptions",
        component: AdminSubscriptions,
        beforeEnter: isAdminOrSupportGuard
      }
    ]
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout
  },
  {
    path: "/set-user-password",
    name: "set-password",
    component: SetPassword
  },
  {
    path: "/activate-account",
    name: "activate-account",
    component: ActivateAccount
  },
  {
    path: "/speechexec-signup",
    component: SimpleLayout,
    children: [
      {
        path: "",
        name: "speechexec-signup",
        component: SpeechExecPurchase,
        alias: ["/speechexec-purchase"]
      }
    ]
  },
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});
registerGuard(router);
export default router;
