var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-tag":"address-form"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":4}},[_c('label',[_vm._v(_vm._s(_vm.name))])]),_c('v-col',{attrs:{"sm":8}},[_c('v-text-field',{attrs:{"outlined":"","solo":"","maxlength":30,"data-tag":"address-form-main-address"},model:{value:(_vm.addressData.addressLineOne),callback:function ($$v) {_vm.$set(_vm.addressData, "addressLineOne", $$v)},expression:"addressData.addressLineOne"}})],1)],1),(
      _vm.addressData.addressLineOne.length > 25 || _vm.addressData.addressLineTwo
    )?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":4}},[_c('label',[_vm._v("Address Line 2")])]),_c('v-col',{attrs:{"sm":8}},[_c('v-text-field',{attrs:{"outlined":"","solo":"","data-tag":"address-form-address-line-two"},model:{value:(_vm.addressData.addressLineTwo),callback:function ($$v) {_vm.$set(_vm.addressData, "addressLineTwo", $$v)},expression:"addressData.addressLineTwo"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":4}},[_c('label',[_vm._v("City")])]),_c('v-col',{attrs:{"sm":8}},[_c('v-text-field',{attrs:{"outlined":"","solo":"","data-tag":"address-form-city"},model:{value:(_vm.addressData.city),callback:function ($$v) {_vm.$set(_vm.addressData, "city", $$v)},expression:"addressData.city"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":4}},[_c('label',[_vm._v("Country")])]),_c('v-col',{attrs:{"sm":8}},[_c('v-autocomplete',{attrs:{"items":_vm.customer.countriesList,"item-text":"name","outlined":"","solo":"","data-tag":"address-form-country"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(item.name))]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}]),model:{value:(_vm.addressData.country),callback:function ($$v) {_vm.$set(_vm.addressData, "country", $$v)},expression:"addressData.country"}})],1)],1),(_vm.hasProvince)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":4}},[_c('label',[_vm._v("Province")])]),_c('v-col',{attrs:{"sm":8}},[_c('v-autocomplete',{attrs:{"outlined":"","solo":"","items":_vm.provincesList,"item-text":"name","data-tag":"address-form-province"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}],null,false,4081757139),model:{value:(_vm.addressData.provinceOrState),callback:function ($$v) {_vm.$set(_vm.addressData, "provinceOrState", $$v)},expression:"addressData.provinceOrState"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":4}},[_c('label',[_vm._v("ZIP")])]),_c('v-col',{attrs:{"sm":8}},[_c('v-text-field',{attrs:{"outlined":"","solo":"","data-tag":"address-form-zip-code"},model:{value:(_vm.addressData.zipCode),callback:function ($$v) {_vm.$set(_vm.addressData, "zipCode", $$v)},expression:"addressData.zipCode"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":4}},[_c('label',[_vm._v("Phone")])]),_c('v-col',{attrs:{"sm":8}},[_c('v-text-field',{attrs:{"outlined":"","solo":"","data-tag":"address-form-phone"},model:{value:(_vm.addressData.phone),callback:function ($$v) {_vm.$set(_vm.addressData, "phone", $$v)},expression:"addressData.phone"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":4}},[_c('label',[_vm._v("Contact Name")])]),_c('v-col',{attrs:{"sm":8}},[_c('v-text-field',{attrs:{"outlined":"","solo":"","data-tag":"address-form-contact-name"},model:{value:(_vm.addressData.attention),callback:function ($$v) {_vm.$set(_vm.addressData, "attention", $$v)},expression:"addressData.attention"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }