



























































































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
 name: "SubscriptionsFilters",
 data: () => ({
  expandSearch: false,
  filter: "activation_date",
  date: null as Date | null,
  menu: false,
  date2: null as Date | null,
  menu2: false,
 }),
 computed: {
  ...mapState(["subscription"]),
  items() {
   return [
    { text: this.$t("activation_date"), value: "activation_date" },
    { text: this.$t("expiration_date"), value: "expiration_date" },
   ];
  },
 },
 created() {
  this.$store.dispatch("setSortBy", "activatedAt");
 },
 methods: {
  filterSearch() {},
  sortTable(value: string) {
   if (value === "activation_date") {
    this.$store.dispatch("setSortBy", "activatedAt");
   } else {
    this.$store.dispatch("setSortBy", "expiresAt");
   }
  },
 },
});
