import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {

      light: {
        primary: '#008ee8',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#de7510',
        background: '#1b578e'
      },
      dark: {
        primary: '#008ee8',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#de7510',
        background: "#b71c1c"
      }
    },
  },
});
