





















import Vue from "vue";
import DictationLogo from "@/components/_logos/PhilipsDictation.vue";
export default Vue.extend({
 name: "NotFound",
 components: {
  DictationLogo,
 },
});
