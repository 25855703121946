<template>
  <json-csv
    :data="data"
    :name="csvTitle"
    :delimiter="delimiter"
    :separator-excel="true"
    :labels="csvHeaders"
    class="json-wrapper"
  >
    <v-btn v-if="isButton" :color="color" :dense="dense" class="mx-1" :outlined="outline">
      <v-icon v-if="icon">{{ icon }}</v-icon>
      {{ $t(label) }}
    </v-btn>
    <span v-else :class="color">
      <v-icon v-if="icon">{{ icon }}</v-icon>
      {{ $t(label) }}
    </span>
  </json-csv>
</template>

<script>
import JsonCsv from "vue-json-csv";

export default {
  name: "JsonToCsv",
  components: { JsonCsv },
  props: {
    data: Array,
    csvTitle: String,
    delimiter: String,
    label: String,
    outline: {
      type: Boolean,
      default: false
    },
    isButton: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
    csvHeaders: {
      type: Array
    },
    icon: String
  },
  data() {
    return {};
  },

  methods: {}
};
</script>

<style>
.json-wrapper {
  display: contents;
}
</style>
