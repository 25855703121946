






























import Vue from "vue";
import PartnerSupport from "../components/PartnerSupport.vue";
import SubpartnerList from "../components/SubpartnerList.vue";
import PartnerEmployees from "../components/PartnerEmployees.vue";
import { filteredPartnerAccountNavigationItems } from "../permissions/navigation-permission";
import AuthenticationService from "../services/AuthenticationService";
export default Vue.extend({
  name: "PersonalSettings",
  components: {
    PartnerSupport,
    SubpartnerList,
    PartnerEmployees
  },
  computed: {
    selectedAccount(): any {
      return this.$store.getters.selectedAccount;
    }
  },
  watch: {
    tab() {
      switch (this.tab) {
      }
    }
  },
  async created() {
    await this.setNavigationItems();
  },
  data: () => ({
    tab: null,
    navItems: [] as any
  }),
  methods: {
    async setNavigationItems() {
      this.navItems = [];

      /* All roles for testing */
      if (this.selectedAccount) {
        this.navItems = filteredPartnerAccountNavigationItems;
      }
    }
  }
});
