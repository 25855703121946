












































































































import Vue from "vue";
import CustomerService from "../../services/CustomerService";
import SubscriptionService from "../../services/SubscriptionService";

import type {
  SpeechProfileTransfer
} from "../../types/customer";
interface Country {
 code: string;
 currency?: string;
 hasProvinces?: boolean;
 name: string;
 region?: string;
}
interface State {
 id: string;
 name: string;
}
interface Profile {
 sapId: string;
 language: string;
 password: string;
 firstName: string;
 lastName: string;
 company: string;
 address: string;
 zipCode: string;
 industry: string;
 phone: string;
 jobPosition: string;
 country: Country;
 state: State;
 city: string;
 checkbox: boolean;
}
export default Vue.extend({
 name: "ChangeCustomer",
 props: {
    subscription: { type: Object, required: true},
  },
 data: () => ({
  message: "",
  form: null,
  email: "",
  isNewProfile: false,
  select: null,
  languages: ["en", "de", "fr"],
  country: {} as Country,
  profile: {
   sapId: "",
   language: "",
   password: "",
   firstName: "",
   lastName: "",
   company: "",
   address: "",
   zipCode: "",
   industry: "",
   phone: "",
   jobPosition: "",
   country: {} as Country,
   state: {} as State,
   city: "",
   checkbox: false,
  } as Profile,
  emptyProfile: {
   sapId: "",
   language: "",
   password: "",
   firstName: "",
   lastName: "",
   company: "",
   address: "",
   zipCode: "",
   industry: "",
   phone: "",
   jobPosition: "",
   country: {} as Country,
   state: {} as State,
   city: "",
   checkbox: false,
  } as Profile,
  show1: false,
  industries: [
   {
    value: 0,
    text: "Other",
   },
   {
    value: 1,
    text: "Construction",
   },
   {
    value: 2,
    text: "Education",
   },
   {
    value: 3,
    text: "FinancialServices",
   },
   {
    value: 4,
    text: "Government",
   },
   {
    value: 5,
    text: "Healthcare",
   },
   {
    value: 6,
    text: "Insurance",
   },
   {
    value: 7,
    text: "Legal",
   },
   {
    value: 8,
    text: "PublicSector",
   },
   {
    value: 9,
    text: "RealEstate",
   },
  ],
  jobs: [
   "Business Owner",
   "Executive Management",
   "Senior Management",
   "Management",
   "Team Leader",
   "Employee",
  ],
  countries: [] as Country[],
  showStates: false,
  states: [] as State[],
  stateList: [] as {
   country: string;
   data: State[];
  }[],
  validData: false,
  oldCustomerUuid: "",
  subscriptionUuid:"",
  hasErrors: false,
  productKey: "",
  oldEmail: "",
  newEmail:"",
  lazy: false,
  loadingCheck: false,
  loadingCreate: false,
  loadingTransfer: false,
  customerData: {} as { uuid: string },
  isFormValid: false,
 }),
 created() {
  this.oldCustomerUuid = this.$props.subscription?.customer.uuid;
  this.subscriptionUuid = this.$props.subscription?.uuid;
  this.productKey = this.$props.subscription?.licenses?.identifier;
  this.oldEmail = this.$props.subscription?.licenses?.email;
 },
 methods: {
  async validate() {
   this.loadingCheck = true;
   this.isNewProfile = false;
   this.validData = false;
   this.hasErrors = false;
   this.message = ""
   const response = await CustomerService.getCustomerByEmail(this.email);
   if (response && response.length > 0 && response[0].uuid) {
    this.customerData = response[0];
    this.isNewProfile = false;
    this.validData = true;
    this.newEmail = this.email;
   } else if (response.length === 0) {
    this.isNewProfile = true;
   } else {
    this.hasErrors = true;
    this.message = this.$t("there_were_some_errors_retriving_profile");
    this.isNewProfile = false;
    this.validData = false;
   }
   this.loadingCheck = false;
  },
  async transferKey() {
   this.loadingTransfer = true;
   const speechProfileTransfer: SpeechProfileTransfer = {
    subscriptionUuid: this.subscriptionUuid,
    oldCustomerUuid: this.oldCustomerUuid,
    customerUuid: this.customerData.uuid,
    customerEmail: this.newEmail.trim(),
    productKey: this.productKey.trim().toUpperCase(),
    oldCustomerEmail: this.oldEmail.trim(),
   };
   this.message = "";
   const response = await SubscriptionService.transferSpeechExecSubscription(
    speechProfileTransfer
   );

   if (response) {
    this.$analytics.track("customerChanged");
    this.hasErrors = false;
    this.message = this.$t("key_was_transferred_from_to", {oldEmail:this.oldEmail, newEmail: this.newEmail})
    this.$emit('closeDialog');
   } else {
    this.hasErrors = true;
    this.message = this.$t("errors_while_transferring_from_to", {oldEmail:this.oldEmail, newEmail: this.newEmail})

   }
   this.isNewProfile = false;
   this.validData = false;
   this.loadingTransfer = false;
  },
 },
});
