import Vue from "vue";
import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import VueI18Next from "@panter/vue-i18next";
import lang from "../locales/index";

Vue.use(VueI18Next);

const locales = lang;
const options = {
  // order and from where user language should be detected
  order: ['localStorage', 'navigator', 'cookie', 'sessionStorage', 'querystring', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],

}
i18next.use(LanguageDetector).init({
  detection: options,
  fallbackLng: "en",
  resources: {
    en: { common: locales.en },
    de: { common: locales.de },
    fr: { common: locales.fr }
  }
});

export default new VueI18Next(i18next);