





























































































































































































































































































































































import { DataTablePagination, transformPagination } from "../types/pagination";

import Vue from "vue";
import moment from "moment";
import JsonToCsv from "./JsonToCsv.vue";
import PartnerService from "../services/PartnerService";

type DebounceFunction = (a?: any) => void;
interface OrderSubscription {
  couponCode: string;
  couponName: string;
  createdAt: string;
  discount: string;
  expirationDate: string;
  foreignPricingOptionId: string;
  foreignSubscriptionId: string;
  licenseEmail: string;
  licenseExpiresAt: string;
  licenseIdentifier: string;
  licenseSerialNumber: string;
  orderCost: string;
  planCode: string;
  planName: string;
  previousExpirationDate: string;
  subscriptionId: string;
  subscriptionType: string;
  tax: string;
  taxRate: string;
  total: string;
  unitPrice: string;
  updatedAt: string;
  uuid: string;
}
interface Order {
  couponDiscount?: string;
  createdAt: string;
  currency: string;
  externalReferenceNumber?: string;
  extraDiscount?: string;
  extraMargin?: string;
  foreignInvoiceId?: string;
  foreignOrderId: string;
  isTest?: string;
  orderSubscriptions: OrderSubscription[];
  organizationUuid: string;
  partnerMargin: string;
  partnerMarginRate: string;
  partnerName: string;
  payableAmount: string;
  paymentMethod?: string;
  payoutCurrency: string;
  purchasedAt: string;
  refundReason?: string;
  refundStatus?: string;
  refundType?: string;
  sapInvoiceId?: string;
  status: string;
  total: string;
  updatedAt: string;
  uuid: string;
}
enum RefundStatus {
  not_requested = 0,
  requested = 1,
  completed = 2
}

export default Vue.extend({
  name: "PartnerOrders",
  components: {
    // CataloguePartner,
    JsonToCsv
    // TranscriptionSettings
  },

  data: () => {
    return {
      moment,
      orders: [] as any,
      ordersToExport: [] as any,
      subscriptionsToExport: [] as any,
      loader: null,
      loading: false,
      search: "",
      searchTimerId: 0,
      loadingExport: false,
      dialogRefund: false,
      loadRefundButton: false,
      refundedItem: {} as Order,
      emptyOrder: {
        createdAt: "",
        currency: "",
        foreignOrderId: "",
        orderSubscriptions: [],
        organizationUuid: "",
        partnerMargin: "",
        partnerMarginRate: "",
        partnerName: "",
        payableAmount: "",
        payoutCurrency: "",
        purchasedAt: "",
        status: "",
        total: "",
        updatedAt: "",
        uuid: ""
      } as Order,
      reason: "",
      comment: "",

      /* Table headers */
      headers: [] as {
        text: string;
        value: string;
        filter?: any;
        sortable?: boolean;
        align?: string;
        width?: string;
      }[],
      headersExportOrders: {} as any,
      headersSubscription: [] as {
        text: string;
        value: string;
        filter?: any;
        sortable?: boolean;
        align?: string;
        width?: string;
      }[],
      /* Filters */
      date: null as Date | null,
      menu: false,
      date2: null as Date | null,
      menu2: false,
      filterOrderType: "all",
      orderDateMin: null as Date | null,
      orderDateMax: null as Date | null,
      selectedPlan: "",
      plans: ["all"] as any,
      /* Pagination */
      options: {} as any,
      rowsPerPageItems: [2, 5, 10, 25, 50, 100],

      pagination: {
        page: 1,
        itemsPerPage: 10,
        itemsLength: 10,
        pageStart: undefined,
        pageStop: undefined,
        pageCount: undefined
      } as DataTablePagination
    };
  },
  computed: {
    reasonItems(): {
      value: string;
      text: string;
    }[] {
      return [
        {
          text: this.$t("product_not_received"),
          value: "product_not_received"
        },
        { text: this.$t("incorrect_product"), value: "incorrect_product" },
        {
          text: this.$t("product_not_matching"),
          value: "product_not_matching"
        },
        { text: this.$t("product_not_working"), value: "product_not_working" },
        { text: this.$t("unwanted_renewal"), value: "unwanted_renewal" },
        { text: this.$t("duplicate_order"), value: "duplicate_order" },
        { text: this.$t("other"), value: "other" }
      ];
    }
  },
  created() {
    this.headers = [
      { text: this.$t("purchase_date"), value: "purchasedAt" },
      { text: this.$t("partner_name"), value: "partnerName" },
      { text: this.$t("order_id"), value: "foreignOrderId" },
      { text: this.$t("invoice_order_no"), value: "sapInvoiceId" },
      { text: this.$t("status"), value: "status" },
      { text: this.$t("total"), value: "total" },
      { text: this.$t("reference"), value: "externalReferenceNumber" },
      { text: this.$t("actions"), value: "actions", sortable: false }
    ];
    /*
    this.headersExportOrders = {
      purchasedAt: this.$t("purchase_date"),
      partnerName: this.$t("partner_name"),
      foreignOrderId: this.$t("order_id"),
      sapInvoiceId: this.$t("invoice_sap_id"),
      status: this.$t("status"),
      externalReferenceNumber: this.$t("reference"),
      paymentMethod: this.$t("payment_method"),
      currency: this.$t("currency"),
      total: this.$t("total")
    };
    */

    this.headersSubscription = [
      { text: this.$t("order_type"), value: "subscriptionType" },
      { text: this.$t("product_key"), value: "licenseIdentifier" },
      { text: this.$t("serial_number"), value: "licenseSerialNumber" },
      {
        text: this.$t("tco_subscription_reference"),
        value: "foreignSubscriptionId"
      },
      { text: this.$t("expiration_date"), value: "licenseExpiresAt" },
      { text: this.$t("product"), value: "planCode" },
      { text: this.$t("price"), value: "unitPrice" },
      { text: this.$t("discount"), value: "discount" },
      { text: this.$t("total"), value: "total" }
    ];
    this.getOrders();
  },
  methods: {
    debounce(debounceFunction: DebounceFunction, resetPagination = true) {
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        if (resetPagination) this.resetPagination();
        debounceFunction();
      }, 900);
    },
    async getOrders() {
      /* Because there are 2 sync listeners in the table and in the footer,
        we need to cancel duplicate queries when the options change. */
      if (this.loading) {
        return;
      }

      this.loading = true;
      const partnerUuid = this.$store.getters.selectedAccount.partner.uuid;

      const queryParams = {
        orderType: this.filterOrderType,
        orderDateMin: this.orderDateMin
          ? moment(this.orderDateMin)
              .utc()
              .format("YYYY-MM-DD HH:mm")
          : undefined,
        orderDateMax: this.orderDateMax
          ? moment(this.orderDateMax)
              .endOf("day")
              .utc()
              .format("YYYY-MM-DD HH:mm")
          : undefined,
        subscribedPlan: this.selectedPlan ? this.selectedPlan : undefined,
        search: this.search ? this.search : undefined,

        /* Pagination */
        currentPage: this.pagination.page || 1,
        perPage: this.pagination.itemsPerPage || 5,
        sortBy: this.options?.sortBy?.[0]
          ? this.options?.sortBy?.[0]
          : undefined,
        sortDesc: this.options?.sortDesc?.[0]
          ? this.options?.sortDesc?.[0]
          : undefined
      };
      const response = await PartnerService.getOrders(partnerUuid, queryParams);

      if (response && response.data) {
        //TODO: Find a better way to fill the plan dropdown. This approach only accounts for the currently visible orders
        /*
        let planCodeArray: string[] = [];
        response.data.forEach((order: any)=>{
          order.orderSubscriptions.forEach((orderSubscription: any)=>{
            planCodeArray.push(orderSubscription.planCode);
          })
        })
        this.plans = [...new Set(planCodeArray)].sort().map((val: any)=>{
          return {
            text: this.$t(val),
            value: val
          }
        })
        */
        this.pagination = transformPagination(response.pagination);
        this.orders = response.data;
      }

      this.loading = false;

      this.$analytics.track("ordersQueried", {
        ...queryParams
      });
    },
    flattenOrders(orders: Order[]) {
      this.ordersToExport = orders.map((order: any) => {
        return {
          "Purchase date": moment(order.purchasedAt).format("YYYY-MM-DD HH:mm"),
          "Partner name": order.partnerName,
          "Order ID": order.foreignOrderId,
          "SAP order no.": order.sapInvoiceId,
          Status: order.status,
          "Refund status": order.refundStatus
            ? this.$t(this.returnRefundStatus(order.refundStatus))
            : "",
          "Refund reason": this.$t(order.refundReason),
          "Payment method": order.paymentMethod,
          Total: order.total,
          Currency: order.currency,
          "External reference": order.externalReferenceNumber
        };
      });
    },
    async exportOrders() {
      this.loadingExport = true;
      const partnerUuid = this.$store.getters.selectedAccount.partner.uuid;
      let orderList = [];

      const queryParams = {
        orderType: this.filterOrderType,
        orderDateMin: this.orderDateMin
          ? moment(this.orderDateMin)
              .utc()
              .format("YYYY-MM-DD HH:mm")
          : undefined,
        orderDateMax: this.orderDateMax
          ? moment(this.orderDateMax)
              .endOf("day")
              .utc()
              .format("YYYY-MM-DD HH:mm")
          : undefined,
        subscribedPlan: this.selectedPlan ? this.selectedPlan : undefined,
        search: this.search ? this.search : undefined,

        /* Pagination */
        currentPage: 1,
        perPage: this.pagination.itemsLength || 10000,
        sortBy: undefined,
        sortDesc: undefined
      };
      const response = await PartnerService.getOrders(partnerUuid, queryParams);

      this.loadingExport = false;
      if (response) {
        orderList = response.data;
      }

      this.flattenOrders(orderList);

      setTimeout(() => {
        const elem: any = this.$refs.exportOrders;
        const result = elem.$el.click();
      }, 200);
    },
    flattenOrderSubscriptions(orders: Order[]) {
      this.subscriptionsToExport = [];
      orders.forEach((order: any) => {
        order.orderSubscriptions.forEach((orderSubscription: any) => {
          this.subscriptionsToExport.push({
            "Purchase date": moment(order.purchasedAt).format(
              "YYYY-MM-DD HH:mm"
            ),
            "Partner name": order.partnerName,
            "Order ID": order.foreignOrderId,
            "SAP ordert no.": order.sapInvoiceId,
            Status: order.status,
            "Refund status": order.refundStatus
              ? this.$t(this.returnRefundStatus(order.refundStatus))
              : "",
            "External reference": order.externalReferenceNumber,
            "Payment method": order.paymentMethod,
            "Order type": orderSubscription.subscriptionType,
            "Subscription reference": orderSubscription.subscriptionId,
            "Product key": orderSubscription.licenseIdentifier,
            "Serial number": orderSubscription.licenseSerialNumber,
            "Expiration date": orderSubscription.licenseExpiresAt,
            Email: orderSubscription.licenseEmail,
            "Unit price": orderSubscription.unitPrice,
            "Coupon code": orderSubscription.couponCode,
            "Coupon name": orderSubscription.couponName,
            "Coupon discount": orderSubscription.couponDiscount,
            Discount: orderSubscription.discount,
            Plan: orderSubscription.planName,
            "Plan code": orderSubscription.planCode,
            "Order total": orderSubscription.total,
            Currency: order.currency
          });
        });
      });
    },
    async exportOrderSubscriptions() {
      this.loadingExport = true;
      const partnerUuid = this.$store.getters.selectedAccount.partner.uuid;
      let orderList = [];

      const queryParams = {
        orderType: this.filterOrderType,
        orderDateMin: this.orderDateMin
          ? moment(this.orderDateMin)
              .utc()
              .format("YYYY-MM-DD HH:mm")
          : undefined,
        orderDateMax: this.orderDateMax
          ? moment(this.orderDateMax)
              .endOf("day")
              .utc()
              .format("YYYY-MM-DD HH:mm")
          : undefined,
        subscribedPlan: this.selectedPlan ? this.selectedPlan : undefined,
        search: this.search ? this.search : undefined,

        /* Pagination */
        currentPage: 1,
        perPage: this.pagination.itemsLength || 10000,
        sortBy: undefined,
        sortDesc: undefined
      };
      const response = await PartnerService.getOrders(partnerUuid, queryParams);

      this.loadingExport = false;
      if (response) {
        orderList = response.data;
      }

      this.flattenOrderSubscriptions(orderList);

      setTimeout(() => {
        const elem: any = this.$refs.exportOrderSubscriptions;
        const result = elem.$el.click();
      }, 200);
    },
    resetPagination() {
      this.pagination.page = 1;
    },
    openRefundDialog(item: Order) {
      this.dialogRefund = true;
      this.refundedItem = item;
    },
    closeRefundDialog() {
      this.dialogRefund = false;
      this.refundedItem = this.emptyOrder;
      this.reason = "";
      this.comment = "";
    },
    async requestRefund() {
      this.loadRefundButton = true;

      const response = await PartnerService.requestRefund({
        partnerUuid: this.refundedItem.organizationUuid,
        orderUuid: this.refundedItem.uuid,
        reason: this.reason,
        comment: this.comment
      });

      if (response) {
        this.getOrders();
      }
      this.loadRefundButton = false;
      this.closeRefundDialog();
    },
    returnRefundStatus(number: number) {
      return RefundStatus[number];
    }
  }
});
