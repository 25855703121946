import type { AxiosError } from "axios";
import store from '@/store';
import { apiClient } from "@/plugins/axios";
import { msalInstance } from "@/plugins/msalPlugin";


const getUserInfo = async (token: string, email: string) => {
  //let token = await msalInstance.getToken();

  const response = await apiClient.get("/auth/me", {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }).then((responseApi: any) => {
    store.dispatch("setAuthenticationErrors", false)
    return responseApi.data;
  }).catch(async (error: AxiosError) => {
    await error?.globalHandler();
  })

  return response;
};

const getUserPermissions = async () => {
  const token = await msalInstance.getToken();

  const response = await apiClient.get("/auth/me/permissions", {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }).then((responseApi: any) => {
    store.dispatch("setAuthenticationErrors", false)
    return responseApi.data;
  }).catch(async (error: AxiosError)=>{
    await error?.globalHandler();
  })

  return response;
};

const getUserTcoSso = async () => {
  const token = await msalInstance.getToken();
  const response = await apiClient.get("/auth/channel-manager-sso", {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }).then((responseApi: any) => {
    return responseApi.data;
  }).catch(async (error: AxiosError) => {
    if (error.message.includes("401")) {
      const email = store.getters.userInfo.email;
      store.dispatch("createNotification", {
        type: "error",
        text: email + " was not assign to a 2Checkout profile. Please contact support@speech.com.",
        hasTimeout: true,
        timeout: 10000
      })
    }
    else {
      await error?.globalHandler();
    }
  })
  return response;
}

export default {
  /*   registerUser(data: object) {
      return apiClient.post("/register/user", data);
    },
    registerTrial(data: object) {
      return apiClient.post("/customers/speechlive/trial", data);
    },
    resetPassword(data: object) {
      return apiClient.post("/users/password-reset", data);
    },
    forgotPassword(data: object) {
      return apiClient.post("/users/password-reset/request", data);
    },
    getWorkflowRedirectLink(subscriptionUUID: string, customerUUID: string) {
      if (!customerUUID) {
        router.push("account-selector");
      }
  
      const token = await msalInstance.getToken();;
  
      return apiClient.get(
        `/customers/${customerUUID}/subscriptions/${subscriptionUUID}/speechlive/workflow/login`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    }, */
    getUserInfo,
    getUserPermissions,
    getUserTcoSso,
};
