






















import Vue from "vue";
import { mapActions, mapState } from "vuex";
import store from "../store/index";
import LanguageSwitcher from "../components/LanguageSwitcher.vue";

export default Vue.extend({
  name: "SimpleLayout",
  components: {
    LanguageSwitcher
  },
  computed: {
    ...mapState(["notification"])
  }
});
