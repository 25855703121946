





































import Vue from "vue";
import PartnerOrders from "../components/PartnerOrdersList.vue";
import Commissions from "../views/Commissions.vue";
import AddOrder from "../components/PartnerAddOrder.vue";
import { filteredPartnerOrdersNavigationItems } from "../permissions/navigation-permission";
export default Vue.extend({
  name: "PersonalSettings",
  components: {
    PartnerOrders,
    Commissions,
    AddOrder
  },
  computed: {
    selectedAccount(): any {
      return this.$store.getters.selectedAccount;
    }
  },
  watch: {
    tab() {
      switch (this.tab) {
      }
    }
  },
  async created() {
    await this.setNavigationItems();
  },
  data: () => ({
    navItems: [] as any,
    tab: null
  }),
  methods: {
    async setNavigationItems() {
      this.navItems = [];

      /* All roles for testing */
      if (this.selectedAccount) {
        this.navItems = filteredPartnerOrdersNavigationItems;
      }
    }
  }
});
