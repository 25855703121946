


























































































































































































































































































































































import Vue from "vue";
import DictationLogo from "../components/_logos/PhilipsDictation.vue";
import CountryService from "../services/CountryService";
import axios, { AxiosError, AxiosResponse } from "axios";
import IpLocationService from "../services/IpLocationService";
import CustomerService from "../services/CustomerService";
import { SpeechProfile } from "../types/customer";

interface Country {
  code: string;
  currency?: string;
  hasProvinces?: boolean;
  name: string;
  region?: string;
}
interface State {
  id: string;
  name: string;
}

interface Profile {
  email: string;
  language: string;
  password: string;
  firstName: string;
  lastName: string;
  company: string;
  address: string;
  zipCode: string;
  industry: string;
  phone: string;
  jobPosition: string;
  country: Country;
  state: State;
  city: string;
  checkbox: boolean;
}

export default Vue.extend({
  name: "SpeechExecPurchase",
  components: {
    DictationLogo
  },

  data: function() {
    return {
      form: null,
      form_credentials: null,
      form_personal: null,
      form_company: null,
      e1: 1,
      select: null,
      languages: [
        { value: "en", text: "English" },
        { value: "de", text: "Deutsch" },
        { value: "fr", text: "Français" }
      ],
      country: {} as Country,
      profile: {
        email: "",
        language: "",
        password: "",
        firstName: "",
        lastName: "",
        company: "",
        address: "",
        zipCode: "",
        industry: "",
        phone: "",
        jobPosition: "",
        country: {
          code: "",
          name: ""
        } as Country,
        state: {
          id: "",
          name: ""
        } as State,
        city: "",
        checkbox: false
      } as Profile,
      show1: false,
      show2: false,
      industries: [
        {
          value: 0,
          text: "Other"
        },
        {
          value: 1,
          text: "Construction"
        },
        {
          value: 2,
          text: "Education"
        },
        {
          value: 3,
          text: "FinancialServices"
        },
        {
          value: 4,
          text: "Government"
        },
        {
          value: 5,
          text: "Healthcare"
        },
        {
          value: 6,
          text: "Insurance"
        },
        {
          value: 7,
          text: "Legal"
        },
        {
          value: 8,
          text: "PublicSector"
        },
        {
          value: 9,
          text: "RealEstate"
        }
      ],

      rules: {
        email: [
          (v: any) => !!v || `${(this as any).$t("email_required")}`,
          (v: any) =>
            !v ||
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) ||
            `${(this as any).$t("email_not_valid")}`
        ],
        password: [
          (v: any) => !!v || (this as any).$t("please_type_password"),
          (v: any) =>
            (v && v.length >= 8) || (this as any).$t("minimum_8_characters"),
          (v: any) =>
            (v && /[A-Z]/.test(v)) ||
            (this as any).$t("must_contain_uppercase_character"),
          (v: any) =>
            (v && /[a-z]/.test(v)) ||
            (this as any).$t("must_contain_lowercase_character"),
          (v: any) =>
            (v && /[!@#\$%\^\&\*\)\(\+=\.\<\>\{\}\[\]\:\;'"\|~`_\-]/.test(v)) ||
            (this as any).$t("must_contain_special_character"),
          (v: any) =>
            (v && /[0-9]/.test(v)) || (this as any).$t("must_contain_numbers")
        ],
        firstName: [
          (v: any) => !!v || `${(this as any).$t("first_name_required")}`
        ],
        lastName: [
          (v: any) => !!v || `${(this as any).$t("last_name_required")}`
        ],
        phoneNumber: [(v: any) => !!v || (this as any).$t("phone_required")],
        company: [
          (v: any) => !!v || `${(this as any).$t("company_name_required")}`
        ],
        address: [(v: any) => !!v || `${(this as any).$t("address_required")}`],
        country: [
          (v: any) => !!v.code || `${(this as any).$t("country_required")}`
        ],
        state: [
          (v: any) =>
            !!v.id || `${(this as any).$t("state_or_province_required")}`
        ],
        city: [(v: any) => !!v || `${(this as any).$t("city_required")}`],
        zip: [(v: any) => !!v || `${(this as any).$t("zip_code_required")}`]
      },

      jobs: [
        "Business Owner",
        "Executive Management",
        "Senior Management",
        "Management",
        "Team Leader",
        "Employee"
      ],
      countries: [] as Country[],
      showStates: false,
      states: [] as State[],
      stateList: [] as {
        country: string;
        data: State[];
      }[],
      loadingCreate: false,
      profileCreated: false,
      retypePassword: "",
      ip: "",
      isPhoneValid: false,
      isStartedInputOnPhone: false, // Workaround to not display error message after the prefix is automatically filled
      locatedUser: false,
      defaultCountry: {
        code: "de",
        name: "Germany",
        currency: "EUR",
        regionCode: "DACH",
        region: "DACH",
        hasProvinces: false
      }
    };
  },
  async created() {
    /* We need the countries data to map to when locating the user */
    await this.getCountriesData();
    await this.locate();
  },
  computed: {
    passwordRetypeRule() {
      return [
        (v: any) => !!v || `${(this as any).$t("retype_password_required")}`,
        (this as any).profile.password === (this as any).retypePassword ||
          `${(this as any).$t("passwords_dont_match")}`
      ];
    }
  },
  watch: {
    country(value: Country) {
      this.profile.country = value;
      let states = this.stateList.filter(item => {
        return item.country === value?.code;
      });

      if (states.length > 0) {
        this.profile.state = {
          id: "",
          name: ""
        };
        this.showStates = true;
        this.states = states[0].data;
      } else {
        this.profile.state = {
          id: "",
          name: ""
        };
        this.showStates = false;
      }
    }
  },
  methods: {
    async getCountriesData() {
      const response = await CountryService.fetchCountries();
      this.countries = response.data.countries;
      for (const country of this.countries) {
        if (country.hasProvinces) {
          const responseState = await CountryService.fetchProvinces(
            country.code
          );
          this.stateList.push({
            country: country.code,
            data: responseState.data
          });
        }
      }
    },
    handlePhoneValidate(e: any) {
      if (e.isValid) {
        this.isPhoneValid = true;
      } else {
        this.isPhoneValid = false;
      }
    },
    getCountry(code: string): Country {
      const country = this?.countries?.find((country: Country) => {
        return country?.code?.toLowerCase() === code?.toLowerCase();
      });

      if (!country) {
        return this.defaultCountry;
      }

      return country;
    },
    async locate() {
      try {
        /* Primary service */
        const res = await IpLocationService.getIpInformationMain();

        this.ip = res.data.ip || "";
        this.profile.country.code = res.data.country_code;
        this.country = this.getCountry(res.data.country_code);
        this.locatedUser = true;
      } catch {
        try {
          /* Fallback service */
          const res = await IpLocationService.getIpInformationFallback();

          this.ip = res.data.ip || "";
          this.profile.country.code = res.data.country_code;
          this.country = this.getCountry(res.data.country_code);
          this.locatedUser = true;
        } catch {
          /* Couldn't determine user ip */
          this.ip = "";
          this.profile.country.code = this.defaultCountry.code;
          this.country = this.getCountry(this.defaultCountry.code);
          this.locatedUser = true;
        }
      }
    },
    login() {
      this.$router.push({ name: "home" });
    },
    async createProfile() {
      this.loadingCreate = true;

      let detectedLanguage = this.$i18n.i18next.language;
      if (detectedLanguage.includes("-")) {
        detectedLanguage = detectedLanguage.split("-")[0];
      }

      const newUserProfile: SpeechProfile = {
        email: this.profile.email,
        language: detectedLanguage,
        password: this.profile.password,
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        addressLineOne: this.profile.address,
        country: this.profile.country.name,
        countryCode: this.profile.country.code,
        city: this.profile.city,
        zipCode: this.profile.zipCode,
        phoneNumber: this.profile.phone,
        companyName: this.profile.company,
        isConsentEnabled: true,
        stateProvince: this.profile.state.name,
        stateProvinceCode: this.profile.state.id,
        jobPosition: this.profile.jobPosition,
        industry: this.profile.industry
      };
      let response: AxiosResponse | boolean;
      try {
        response = await CustomerService.createSpeechProfile(newUserProfile);
      } catch {
        response = false;
      }
      if (response) {
        this.profileCreated = true;
      } else {
        this.profileCreated = false;
      }
      this.loadingCreate = false;
    }
  }
});
