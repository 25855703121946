















































import Vue from "vue";
import Account from "../components/_account-details/Account.vue";
import CustomerOrders from "../components/_account-details/Orders.vue";
import CustomerService from "../services/CustomerService";
export default Vue.extend({
  name: "AccountDetails",
  components: {
    Account,
    CustomerOrders
  },
  data: () => {
    return {
      tab: null,
      dialog: false,
      menu: [
        {
          title: "account",
          icon: "mdi-badge-account-horizontal"
        },
        /* {
          title: "subscriptions",
          icon: "mdi-devices",
        }, */
        /*         {
          title: "Services",
          icon: "mdi-settings"
        }, */
        /* {
          title: "partner",
          icon: "mdi-store",
        }, */
        {
          title: "orders",
          icon: "mdi-receipt"
        }
        /* {
          title: "transactions",
          icon: "mdi-list-status",
        }, */
      ]
    };
  },
  computed: {
    language(): string {
      return this.$i18n.i18next.language;
    }
  },
  methods: {
    async createCCLink() {
      const customerUuid = this.$store.getters.selectedAccount.customerUuid;
      const response = await CustomerService.getPaymentMethodsLink(
        customerUuid,
        this.language
      );
      if (response && response.link) {
        window.open(response.link, "2Checkout", "status=1,toolbar=0,width=800");
      }
    }
  }
});
