import Vue from "vue";
import router from "vue-router";
import { UserIdentity } from "@/types/account";
import {
  ApplicationInsights,
  ITelemetryItem,
  Util
} from "@microsoft/applicationinsights-web";

interface AnalyticsPluginInstallOptions {
  appInsightsConfig: any;
  appInsights: any;
  router: router;
  instrumentationKey: string;
  baseName: string;
  trackInitialPageView?: boolean;
}

declare module "vue/types/vue" {
  interface Vue {
    $analytics: AnalyticsVuePlugin;
  }
}

class AnalyticsVuePlugin {
  appInsights: ApplicationInsights;
  userIdentity?: UserIdentity;

  public static install(
    vue: typeof Vue,
    options: AnalyticsPluginInstallOptions
  ) {
    if (!options?.router) {
      throw new Error("Router instance not found");
    }
    if (!options?.instrumentationKey) {
      throw new Error("Instrumentation key not found");
    }

    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: options?.instrumentationKey
      }
    });

    const analytics = new AnalyticsVuePlugin(appInsights, options);

    vue.prototype.$analytics = analytics;
  }

  constructor(
    appInsights: ApplicationInsights,
    options: AnalyticsPluginInstallOptions
  ) {
    this.appInsights = appInsights;
    /* Init app insights */
    appInsights.loadAppInsights();

    this.appInsights.addTelemetryInitializer(
      (envelope: ITelemetryItem): void => {
        if (!envelope.tags) {
          envelope.tags = [];
        }

        /* Cloud role name */
        envelope.tags.push({ "ai.cloud.role": "speechid-web" });
      }
    );

    /* Init page view after router is ready */
    if (options.trackInitialPageView === true) {
      this.page(appInsights, options);
    } else {
      options?.router.onReady(() => {
        this.page(appInsights, options);
      });
    }
  }

  public identify(identity: UserIdentity) {
    /* Use this object if we switch provider for user identity */
    this.userIdentity = {
      userId: identity.userId,
      email: identity.email,
      speechIdRoles: identity.speechIdRoles,
      accountLanguage: identity.accountLanguage,
      companyName: identity.companyName,
      industry: identity.industry,
      country: identity.country,
      speechIdUser: identity.speechIdUser
    };

    /* Set adb2c user id */
    this.appInsights.setAuthenticatedUserContext(this.userIdentity?.userId);

    const telemetryInitializer = (envelope: ITelemetryItem): void => {
      if (!envelope.data) {
        envelope.data = {};
      }
      envelope.data["userIdentity.userId"] = this.userIdentity?.userId;
      envelope.data["userIdentity.email"] = this.userIdentity?.email;
      envelope.data[
        "userIdentity.speechIdRoles"
      ] = this.userIdentity?.speechIdRoles;
      envelope.data[
        "userIdentity.accountLanguage"
      ] = this.userIdentity?.accountLanguage;
      envelope.data[
        "userIdentity.companyName"
      ] = this.userIdentity?.companyName;
      envelope.data["userIdentity.email"] = this.userIdentity?.email;
      envelope.data["userIdentity.industry"] = this.userIdentity?.industry;
      envelope.data["userIdentity.country"] = this.userIdentity?.country;
      envelope.data[
        "userIdentity.speechIdUser"
      ] = this.userIdentity?.speechIdUser;
    };
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  public page(
    appInsights: ApplicationInsights,
    options: AnalyticsPluginInstallOptions
  ): void {
    /* Implement page view here */
    const router = options.router;
    const baseName = options.baseName;

    router.beforeEach((route, from, next) => {
      const name = baseName + " / " + route.name;
      appInsights.context.telemetryTrace.traceID = Util.generateW3CId();
      appInsights.context.telemetryTrace.name = route.name as string;
      appInsights.startTrackPage(name);
      next();
    });

    router.afterEach(route => {
      const name = baseName + " / " + route.name;
      const url = location.protocol + "//" + location.host + route.fullPath;
      appInsights.stopTrackPage(name, url);
      appInsights.flush();
    });
  }

  public async track(event: string, properties?: any): Promise<void> {
    /* Implement handler here */
    await this.appInsights.trackEvent({
      name: event,
      properties
    });
  }
}

export default AnalyticsVuePlugin;
