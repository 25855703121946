




































































































































































import Vue from "vue";
import JsonToCsv from "../components/JsonToCsv.vue";
import PartnerService from "../services/PartnerService";

interface Subscription {
  index?: number;
  productKey: string;
  serialNumber: string;
  partnerId: string;
  partnerName?: string;
  status?: {
    type: string;
    message: string;
  };
}
interface SubscriptionExport {
  ProductKey: string;
  SerialNumber: string;
  PartnerId: string;
  PartnerName?: string;
  Status?: string;
}
export default Vue.extend({
  name: "TransferSubscriptions",
  components: {
    JsonToCsv
  },
  data: () => {
    return {
      search: "",
      status: "any_status",
      statusItems: [] as {
        text: string;
        value: string;
      }[],
      loader: null,
      tab: null,
      loading: false,
      loadTransferButton: false,
      disabledUpload: true,
      loadingUpload: false,
      disabledTransfer: true,
      file: null,
      selected: [] as Subscription[],
      subscriptions: [] as Subscription[],
      errors: [] as SubscriptionExport[],
      csvExampleHeaders: ["ProductKey", "SerialNumber", "PartnerId"],
      csvPartnersHeaders: ["PartnerId", "PartnerName"],
      exampleCsv: [
        {
          ProductKey: "PPPP-XXX-XXXX-XXXX-XXXX-XXXX-XX",
          SerialNumber: 111111,
          PartnerId: "1111"
        },
        {
          ProductKey: "PPPP-XXX-XXXX-XXXX-XXXX-XXXX-XX",
          SerialNumber: null,
          PartnerId: "2222"
        },
        {
          ProductKey: null,
          SerialNumber: 333333,
          PartnerId: "3333"
        }
      ],
      partnerCsv: [] as {
        PartnerId: string;
        PartnerName: string;
      }[],
      headers: [] as {
        text: string;
        value: string;
        filter?: any;
        sortable?: boolean;
      }[]
    };
  },
  async created() {
    this.headers = [
      { text: "", value: "", sortable: false },
      { text: this.$t("product_key"), value: "productKey" },
      { text: this.$t("serial_number"), value: "serialNumber" },
      { text: this.$t("partner_id"), value: "partnerId" },
      { text: this.$t("partner_name"), value: "partnerName" },
      {
        text: this.$t("status"),
        value: "status",
        filter: (value: any) => {
          if (!this.status || this.status === "any_status") return true;

          return value.type === this.status;
        }
      }
    ];
    this.statusItems = [
      { text: this.$t("any_status"), value: "any_status" },
      { text: this.$t("status_error"), value: "error" },
      { text: this.$t("status_transferred"), value: "transferred" },
      { text: this.$t("status_ready"), value: "ready" }
    ];
    await this.getSubpartnerList();
  },

  watch: {
    file() {
      if (this.file) {
        this.disabledUpload = false;
      }
    },
    selected() {
      if (this.selected.length > 0) {
        this.disabledTransfer = false;
      } else {
        this.disabledTransfer = true;
      }
    }
  },

  methods: {
    addSelected(item: Subscription) {
      this.selected.indexOf(item) === -1
        ? this.selected.push(item)
        : this.selected.splice(this.selected.indexOf(item), 1);
    },
    async sendData() {
      return await PartnerService.sendSubscriptionsToValidateTransfer(
        this.file
      );
    },
    createExportData() {
      this.errors = [];
      for (let subscription of this.subscriptions) {
        if (subscription.status && subscription.status.type === "error") {
          this.errors.push({
            ProductKey: subscription.productKey,
            SerialNumber: subscription.serialNumber,
            PartnerId: subscription.partnerId,
            PartnerName: subscription.partnerName,
            Status: this.$t(subscription.status.message)
          });
        }
      }
    },
    async uploadData() {
      this.subscriptions = [];
      this.loading = true;
      this.disabledUpload = true;
      this.loadingUpload = true;
      let response = await this.sendData();
      if (response && response.length > 0) {
        this.subscriptions = response;
        this.createExportData();
      } else {
        this.subscriptions = [];
      }
      this.loading = false;
      this.loadingUpload = false;
      this.file = null;
    },
    async sendSelected() {
      let payload = [] as Subscription[];
      for (let selected of this.selected) {
        payload.push({
          productKey: selected.productKey,
          serialNumber: selected.serialNumber,
          partnerId: selected.partnerId
        });
      }
      return await PartnerService.sendSubscriptionsToTransfer(payload);
    },
    refreshSelectedData(
      response: Subscription[],
      copySubscriptions: Subscription[]
    ) {
      for (let selected of this.selected) {
        copySubscriptions.splice(copySubscriptions.indexOf(selected), 1);
      }
      for (let elem of response) {
        copySubscriptions.push(elem);
      }
      this.subscriptions = copySubscriptions;
    },
    async transferSelectedLicenses() {
      this.disabledTransfer = true;
      this.disabledUpload = true;
      this.loading = true;
      let copySubscriptions = this.subscriptions;
      this.subscriptions = [];
      let response = await this.sendSelected();

      if (response && response.length > 0) {
        this.refreshSelectedData(response, copySubscriptions);
      } else {
        this.subscriptions = copySubscriptions;
      }
      this.selected = [];
      this.disabledUpload = false;
      this.loading = false;
    },
    async getSubpartnerList() {
      const partnerUuid = this.$store.getters.selectedAccount.partner.uuid;
      if (partnerUuid) {
        let response = await PartnerService.getSubpartnerList(partnerUuid);
        this.partnerCsv = response.map(
          (elem: {
            city: string;
            country: string;
            language: string;
            name: string;
            salesOrg: string;
            sapId: string;
            uuid: string;
          }) => {
            return { PartnerId: elem.sapId, PartnerName: elem.name };
          }
        );
      }
    }
  }
});
