


































































































































































































































































































































































































































































































































































































import Vue from "vue";
import moment from "moment";
import { Partner, LicenseDetails } from "../types/subscription";
import SubscriptionService from "../services/SubscriptionService";
import CustomerService from "../services/CustomerService";
export default Vue.extend({
  name: "SubscriptionsTable",
  components: {},
  data() {
    return {
      moment,
      search: "",
      enableRenewal: false,
      transcriptionServiceData: {
        status: "Active",
        language: "en",
        transcriptionMinutes: 10
      },
      model: [] as string[],
      items: [] as string[],
      subscriptions: [] as LicenseDetails[],
      nonce: 1,
      dialogTakeOver: false,
      dialogTerminate: false,
      dialogSupport: false,
      minDate: "",
      loading: false,
      loadingTransfer: false,
      loadingRenewalBtn: false,
      loadingUpgradeBtn: false,
      successfulTransfer: false,
      indexClicked: undefined as number | undefined,
      errorTransfer: false,
      filterable: true,
      itemsPerPage: 5,
      searchModel: "" as string,
      pills: "",
      supportInfo: {} as Partner | undefined,
      openedItem: {} as LicenseDetails | undefined,
      headers: [
        { text: "uuid", value: "UUID" },
        {
          text: "Product",
          value: "product"
        },
        { text: "Plan", value: "subscribedPlan.name" },
        { text: "Amount Of subscriptions", value: "amountOfsubscriptions" },
        { text: "Creation Date", value: "creationDate" },
        { text: "Activation Date", value: "activatedAt" },
        {
          text: "Expiration Date",
          value: "expiresAt",
          filter: (value: string) => {
            const $this = this as any;
            if (!$this.chosenMinDate && !$this.chosenMaxDate) {
              return true;
            } else if ($this.chosenMinDate && !$this.chosenMaxDate) {
              return value.substr(0, 10) >= $this.chosenMinDate;
            } else if (!$this.chosenMinDate && $this.chosenMaxDate) {
              return value.substr(0, 10) <= $this.chosenMaxDate;
            }
            return (
              value.substr(0, 10) >= $this.chosenMinDate &&
              value.substr(0, 10) <= $this.chosenMaxDate
            );
          }
        },
        { text: "Subscription Duration", value: "subscriptionDuration" }
      ]
    };
  },
  async created() {
    await this.retrieveData();
  },
  computed: {
    chosenStoreProject(): string {
      return this.$store.getters.chosenProject;
    },
    chosenStorePlan(): string {
      return this.$store.getters.chosenPlan;
    },
    chosenMinDate(): string {
      return this.$store.getters.subscriptionDateMin;
    },
    chosenMaxDate(): string {
      return this.$store.getters.subscriptionDateMax;
    },
    selectedAccount(): any {
      return this.$store.getters.selectedAccount;
    },
    sortBy(): any {
      return this.$store.getters.sortBy;
    },
    language(): string {
      return this.$i18n.i18next.language;
    }
  },
  watch: {
    chosenStoreProject(value: string, prev: string) {
      this.model = this.model.filter(item => item !== prev);
      this.$store.dispatch("setChosenPlan", "");
      if (value != "") {
        this.model.push(value);
        this.searchModel = value;
      }
    },
    chosenStorePlan(value: string, prev: string) {
      this.model = this.model.filter(item => item !== prev);
      if (value != "") {
        this.model.push(value);
      }
    },
    model() {
      if (this.model.length === 0) {
        this.searchModel = "";
        this.$store.dispatch("setChosenProject", "");
        this.$store.dispatch("setChosenPlan", "");
      } else {
        this.searchModel = this.model.toString();
      }
    },
    search(value) {
      if (value !== "" && value) {
        if (this.model.length > 0) {
          this.searchModel = this.model.toString() + "," + value;
        } else {
          this.searchModel = value;
        }
      } else {
        if (this.model.length > 0) {
          this.searchModel = this.model.toString();
        } else {
          this.searchModel = "";
        }
      }
    }
  },
  methods: {
    async retrieveData() {
      this.loading = true;
      this.subscriptions = await SubscriptionService.fetchData(
        this.selectedAccount.customerUuid
      );
      this.loading = false;
      this.$store.dispatch("setSubscriptionsData", this.subscriptions);
      this.$store.dispatch("fetchFilters");
    },
    formatDate(date: Date) {
      return this.$moment(date).format(new Date().toLocaleString());
    },
    displaExpirationMessage(expirationDate: Date) {
      return this.$moment() >= this.$moment(expirationDate);
    },
    async createUpgradeLink(subscriptionUuid: string, index: number) {
      this.loadingUpgradeBtn = true;
      this.indexClicked = index;
      const customerUuid = this.$store.getters.selectedAccount.customerUuid;
      const response = await CustomerService.getUpgradeLinkForSubscription(
        customerUuid,
        subscriptionUuid,
        this.language
      );
      this.loadingUpgradeBtn = false;
      this.indexClicked = undefined;
      if (response && response.link) {
        window.location = response.link;
      }
    },
    async createRenewLink(subscriptionUuid: string, index: number) {
      this.loadingRenewalBtn = true;
      this.indexClicked = index;
      const customerUuid = this.$store.getters.selectedAccount.customerUuid;
      const response = await CustomerService.getRenewalLinkForSubscription(
        customerUuid,
        subscriptionUuid,
        this.language
      );
      this.loadingRenewalBtn = false;
      this.indexClicked = undefined;
      if (response && response.link) {
        window.location = response.link;
      }
    },
    deleteChip(item: string) {
      if (item === this.$store.getters.chosenPlan) {
        this.$store.dispatch("setChosenPlan", "");
      }
      if (item === this.$store.getters.chosenProject) {
        this.$store.dispatch("setChosenPlan", "");
        this.$store.dispatch("setChosenPlan", "");
      }
      this.model = this.model.filter(i => i !== item);
    },
    customFilter: function(
      value: string,
      search: string,
      item: LicenseDetails
    ) {
      search = search.replace(/\s+/g, "").toLowerCase();
      let searchArray;
      if (search.includes(",")) {
        searchArray = search.split(",");
      } else {
        searchArray = [search];
      }
      let valuePresent = false;
      for (let searchValue of searchArray) {
        valuePresent = false;
        const inProduct = item.subscribedProduct.name
          .toString()
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(searchValue);
        const inName = item.subscribedPlan.name
          .toString()
          .replace(/\s+/g, "")
          .toLowerCase()
          .includes(searchValue);
        const inPlanCode = item.subscribedPlan.planCode
          .toString()
          .replace(/\s+/g, "")
          .toLowerCase()
          .includes(searchValue);
        let inSubscriptionDuration = item.subscribedPlan.subscriptionDuration
          .toString()
          .replace(/\s+/g, "")
          .toLowerCase()
          .includes(searchValue);
        let index: any;
        const licenseIdentifiers = [];
        const licenseEmails = [];
        for (index of item.licenses) {
          if (index.identifier) {
            licenseIdentifiers.push(
              index.identifier
                .toString()
                .toLowerCase()
                .includes(searchValue)
            );
          }
          if (index.email) {
            licenseEmails.push(
              index.email
                .toString()
                .toLowerCase()
                .includes(searchValue)
            );
          }
          let identifierExists = false;
          for (index of licenseIdentifiers) {
            if (index) {
              identifierExists = true;
            }
          }
          let emailExist = false;
          for (index of licenseEmails) {
            if (index) {
              emailExist = true;
            }
          }
          if (
            inProduct ||
            inName ||
            inSubscriptionDuration ||
            inPlanCode ||
            emailExist ||
            identifierExists
          ) {
            valuePresent = true;
            break;
          }
        }
        if (valuePresent == false) return false;
      }
      return true;
    },
    async transferSubscription(subscriptionUuid: string) {
      this.loadingTransfer = true;
      const customerUuid = this.$store.getters.selectedAccount.customerUuid;
      const response = await CustomerService.transferSubscription(
        customerUuid,
        subscriptionUuid
      );
      this.loadingTransfer = false;
      if (response) {
        this.successfulTransfer = true;
        this.retrieveData();
      } else {
        this.errorTransfer = true;
      }
    },
    resetTransfer() {
      this.loadingTransfer = false;
      this.successfulTransfer = false;
      this.errorTransfer = false;
      this.dialogTakeOver = false;
    },
    async terminateSubscription(uuid: string) {
      const customerUuid = this.$store.getters.selectedAccount.customerUuid;
      const response = await CustomerService.disableAutoRenewalForSubscription(
        customerUuid,
        uuid
      );
      if (response) {
        this.retrieveData();
      }
      this.dialogTerminate = false;
    }
  }
});
