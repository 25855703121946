



































































































import Vue from "vue";
import LanguageSwitcher from "../../components/LanguageSwitcher.vue";
export default Vue.extend({
 name: "Information",
 components: {
  LanguageSwitcher,
 },
 data: () => ({
  image: null,
  dark: false,
  editPicture: false,
  editName: false,
  editEmail: false,
 }),
 computed: {
  userInfo(): any {
   return this.$store.getters.userInfo;
  },
  selectedAccount(): any {
   return this.$store.getters.selectedAccount;
  },
 },
 methods: {
  /*   previewImage() {
   this.user.src = URL.createObjectURL(this.image);
  }, */
 },
});
