
















































































import Vue from "vue";
export default Vue.extend({
 name: "Address",
 props: {
  addressData: {
   type: Object,
   required: true,
  },
  name: {
   type: String,
   required: true,
  },
  hasProvince: {
   type: Boolean,
   default: false,
  },
 },
});
