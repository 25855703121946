import Vue from "vue";
import Vuex from "vuex";
import * as notification from "./modules/notification";
import subscription from "./modules/subscription";
import authentication from "./modules/authentication";
import customer from "./modules/customer";
/* import * as partner from "./modules/partner"; */

import VueMoment from "vue-moment";
import moment from "moment-timezone";

Vue.use(Vuex);
Vue.use(VueMoment, {
  moment
});
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    notification,
    subscription,
    authentication,
    customer,
  }
});
