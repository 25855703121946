



















import Vue from "vue";
import getEnv from "../utils/env";
import DictationLogo from "../components/_logos/PhilipsDictation.vue";

export default Vue.extend({
  name: "SetPassword",
  components: {
    DictationLogo
  },
  data: () => {
    return {
      email: "" as string | (string | null)[],
      activationUuid: "" as string | (string | null)[],
      loading: true
    };
  },
  async created() {
    this.email = this.$route.query.email;
    this.activationUuid = this.$route.query.activationUuid;
    await this.createPasswordRedirect();
  },
  methods: {
    async createPasswordRedirect() {
      if (this.email && this.activationUuid) {
        const request: any = {
          authority: `https://${getEnv(
            "VUE_APP_AZURE_B2C_TENANT"
          )}.b2clogin.com/${getEnv(
            "VUE_APP_AZURE_B2C_TENANT"
          )}.onmicrosoft.com/${getEnv("VUE_APP_AZURE_B2C_POLICY_REGISTER")}`,

          redirectUri: getEnv(
            "VUE_APP_AZURE_B2C_REDIRECT_URI_ACTIVATE"
          ).toString(),
          extraQueryParameters: {
            email: this.email,
            activationUuid: this.activationUuid
          },
          scopes: [
            `https://${getEnv("VUE_APP_AZURE_B2C_TENANT")}/${getEnv(
              "VUE_APP_AZURE_B2C_CLIENT_ID"
            )}/read`
          ]
        };
        await this.$msal.handleRedirectPromise();
        await this.$msal.acquireTokenRedirect(request);
      } else {
        this.loading = false;
      }
    },
    logout() {
      this.$msal.logout();
    }
  }
});
