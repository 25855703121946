























































import Vue from "vue";
import PhilipsDictationLogo from "../components/_logos/PhilipsDictation.vue";
import AccountSelectorCard from "../components/AccountSelectorCard.vue";

export default Vue.extend({
  name: "AccountSelector",

  components: {
    PhilipsDictationLogo,
    AccountSelectorCard
  },
  computed: {
    userInfo(): any {
      if (this.$store.getters.userInfo) {
        return this.$store.getters.userInfo;
      } else if (this.$msal.data.custom.userInfo) {
        return this.$msal.data.custom.userInfo;
      } else return undefined;
    },
    selectedAccount(): any {
      return this.$store.getters.selectedAccount;
    },
    greeting() {
      let greetDate = new Date();
      let hours = greetDate.getHours();

      if (hours >= 5 && hours <= 11) {
        return this.$t("morning");
      } else if (hours >= 12 && hours <= 17) {
        return "afternoon";
      } else if (hours >= 18 && hours <= 21) {
        return "evening";
      } else if (hours >= 22 && hours <= 4) {
        return "night";
      } else {
        return "hello";
      }
    }
  },
  async created() {
    await this.authenticateUser();
  },
  data: () => {
    return {
      fullName: "",
      email: "",
      companiesList: {},
      loading: false
    };
  },
  methods: {
    async authenticateUser() {
      if (!this.selectedAccount) {
        this.$msal.authenticateUser();
      }
    }
  }
});
