

































































import Vue from "vue";
import router from "../router";
export default Vue.extend({
  name: "NavUserAvatar",
  data: () => ({
    personalSettings: {
      title: "personal_settings",
      icon: "mdi-cog",
      action: () => {
        router.push({ name: "personal-settings" });
      }
    },
    accountSelector: {
      title: "switch_account",
      icon: "mdi-account-box-multiple",
      action: () => {
        router.push({ name: "account-selector" });
      }
    }
  }),
  computed: {
    selectedAccount(): any {
      return this.$store.getters.selectedAccount;
    },
    userName(): string {
      return `${this.selectedAccount.firstName} ${this.selectedAccount.lastName}`;
    },
    initials(): string {
      if (this.userName) {
        return this.userName
          .split(" ", 2)
          .map((item: string) => item[0])
          .join("");
      } else {
        return "";
      }
    },
    items(): any {
      let links = [];
      links.push(this.personalSettings);
      if (this.$store.getters.hasAccountSelector) {
        links.push(this.accountSelector);
      }
      return links;
    }
  },
  methods: {
    logout() {
      this.$msal.logout();
    }
  }
});
