












































































































import Vue from "vue";
import PartnerService from "../services/PartnerService";

import ConfirmDialog from "./_shared/ConfirmDialog.vue"
import type { ConfirmDialogType } from "./_shared/ConfirmDialog.vue";


interface Employee{
  firstName: string;
  lastName: string;
  eMail: string;
}

export default Vue.extend({
  name: "PartnerEmployees",
  components: {
    ConfirmDialog
  },
  data: () => ({
    organizationMembers: [],
    newMember: {} as Employee,
    emptyMember: {
      firstName: "",
      lastName: "",
      eMail: ""
    } as Employee,
    isFormValid: false,
    headers: [] as {
      text: string;
      value: string;
      filter?: any;
      sortable?: boolean;
      align?: string;
      width?: string;
    }[],
    rules:{} as {
      required: any,
      email: any
    },
    search: "",
    dialog: false,
    loading: false,
    disableActions: false,

  }),
  computed: {
    confirmDialog(): ConfirmDialogType {
      return this.$refs.confirmDialog as ConfirmDialogType;
    }
  },
  async created() {
    this.headers = [
      { text: this.$t("username"), value: "userName" },
      { text: this.$t("first_name"), value: "firstName" },
      { text: this.$t("last_name"), value: "lastName" },
      { text: this.$t("email"), value: "eMail" },
      { text: this.$t("actions"), value: "actions", sortable: false }
    ];
    this.rules= {
      required: (value: string)=> !!value || this.$t('required'),
      email: (value: string) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || this.$t('invalid_email')
      }
    }
    this.getOrganizationMembers();
  },
  methods: {
    close () {
      this.dialog = false;
      this.newMember = this.emptyMember;
    },
    async getOrganizationMembers() {
      this.loading = true;

      const partnerUuid = this.$store.getters.selectedAccount.partner.uuid;
      let response = await PartnerService.getOrganizationMembers(partnerUuid);
      if (response) {
        this.organizationMembers = response;
      }

      this.loading = false;
    },
    async deleteOrganizationMember(organizationMemberUuid: string) {
      if (
        await this.confirmDialog.open(
          this.$t("delete_employee"),
          this.$t("delete_employee_confirmation")
        )
      ) {
        this.loading = true;

        const partnerUuid = this.$store.getters.selectedAccount.partner.uuid;

        this.disableActions = true;
        this.$store.dispatch("createNotification", {
          type: "info",
          text: this.$t("deleting_employee"),
          hasTimeout: true,
          timeout: 5000
        });

        await PartnerService.deleteOrganizationMember(
          partnerUuid,
          organizationMemberUuid
        );
        this.disableActions = false;
        await this.getOrganizationMembers();

        this.loading = false;
      }
    },
    async createOrganizationMember(payload: string) {
      this.loading = true;
      const partnerUuid = this.$store.getters.selectedAccount.partner.uuid;
      this.dialog = false;
      this.disableActions = true;
      this.$store.dispatch("createNotification", {
        type: "info",
        text: this.$t("adding_employee"),
        hasTimeout: true,
        timeout: 10000
      });
      await PartnerService.createOrganizationMember(partnerUuid, this.newMember);
      this.newMember = this.emptyMember;
      this.disableActions = false;
      await this.getOrganizationMembers();
      this.loading = false;
    }
  }
});
