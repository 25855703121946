import store from "@/store";
import * as msal from "@azure/msal-browser";
import Vue from "vue";
import _ from "lodash";
import { setScopeHeader, apiClient } from "../plugins/axios";
import router from "@/router";
import type { AxiosError } from "axios";
import type { CustomAccount, CustomerUserInfo } from "../types/account";

interface ExtendedPublicClientApplication extends msal.IPublicClientApplication {
  data: CustomDataObject;
  loginRequest: { scopes: Array<string> };
  authenticateUser(): void
  saveCustomData(key: string, data: any): void,
  authenticateRedirect(): Promise<msal.AccountInfo[]>
  setAuthority(authority: string): void
  getToken(): Promise<string>
  getUserAccount(): Promise<msal.AccountInfo>
}
let msalInstance: ExtendedPublicClientApplication;

interface ExtendedBrowserAuthOptions extends msal.BrowserAuthOptions {
  scopes?: Array<string>;
}

interface ExtendedConfiguration extends msal.Configuration {
  graph?: Response;
  mode?: "redirect" | "popup";
  auth: ExtendedBrowserAuthOptions;
}

interface CustomDataObject  {
  isAuthenticated: boolean,
  accessToken: string,
  idToken: string,
  user?: msal.AccountInfo,
  custom: CustomAccount 
}

export default class msalPlugin extends msal.PublicClientApplication {
  static install(vue: typeof Vue, msalConfig: ExtendedConfiguration) {
    msalInstance = new msalPlugin(msalConfig);
    vue.prototype.$msal = msalInstance;
  }

  extendedConfiguration: ExtendedConfiguration;
  loginRequest: { scopes: Array<string> };

  constructor(options: ExtendedConfiguration) {
    super(options);
    this.extendedConfiguration = { ...options };
    this.loginRequest = { scopes: options.auth.scopes || [] };
    this.getStoredCustomData();
  }
  private emptyProfile = {
    isAccountSelected: false,
    hasAccountSelector: false,
    hasAuthenticationErrors: true,
    selectedAccount: undefined,
    userInfo: undefined
  }
  public data: CustomDataObject = {
    isAuthenticated: false,
    accessToken: '',
    idToken: '',
    user: undefined,
    custom: this.emptyProfile
  };

  private async getSilentToken(
    account: msal.AccountInfo,
    scopes: string[] = ["User.Read"],
    authority?: string
  ): Promise<msal.AuthenticationResult | void> {
    const silentRequest = { account, scopes, authority };

    return await this.acquireTokenSilent(silentRequest).catch(async error => {
      if (error instanceof msal.InteractionRequiredAuthError) {
        // remove the the custom authority to fallback to the default one
        delete silentRequest.authority;

        // fallback to interaction when silent call fails
        return await this.acquireTokenRedirect(silentRequest);
      }
    });
  }

  private async getUserInfo(token: string) {
    const response = await apiClient
      .get("/auth/me", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      })
      .then((responseApi: any) => {
        store.dispatch("setAuthenticationErrors", false);
        return responseApi.data;
      })
      .catch(async (error: AxiosError) => {
        await error?.globalHandler();
      });

    return response;
  }
  private redirectToAccountSelector() {
    if(window.location.pathname!=="/account-selector"){
      router.push("account-selector");
    }
  }

  /**
   * setting the cache and store based on the user profile.
   */
  public async authenticateUser(): Promise<void> {
    // try to get cached profile
    let userInfo = this.data.custom.userInfo as CustomerUserInfo;

    // if no cached profile found getting it from the server
    if (!userInfo) { 
      const token = await this.getToken();
      userInfo = await this.getUserInfo(token).then((authResponse: any)=> {
        // reject if we don't get any response
        return authResponse;
      }).catch(err=>{
        throw err;
      });
    }

    // if cached selected account setup the account selected properties
    if(this.data.custom.selectedAccount) {
      store.dispatch("setIsAccountSelected", true);
      store.dispatch("setSelectedAccount", this.data.custom.selectedAccount)
      store.dispatch("requiresAccountSelector", this.data.custom.hasAccountSelector);
      
      this.saveCustomData("isAccountSelected", true);
      this.saveCustomData("selectedAccount", this.data.custom.selectedAccount);
      this.saveCustomData("hasAccountSelector", this.data.custom.hasAccountSelector);

      setScopeHeader(this.data.custom.selectedAccount.roles[0]);
    } else {
      // case when customer profile (one or more) and partner profile
      if(userInfo?.customerProfiles && userInfo?.customerProfiles?.length > 0 && userInfo.partnerProfile) {
        store.dispatch("requiresAccountSelector", true);
        this.saveCustomData("hasAccountSelector", true);
        this.redirectToAccountSelector()
      }
       // case when multiple customer profile
      else if(userInfo?.customerProfiles && userInfo?.customerProfiles?.length > 1) {
        store.dispatch("requiresAccountSelector", true);
        this.saveCustomData("hasAccountSelector", true);
        this.redirectToAccountSelector()
      }
      // case when partner profile
      else if (userInfo?.partnerProfile) {  
        store.dispatch("setIsAccountSelected", true);
        store.dispatch("setSelectedAccount", userInfo.partnerProfile);
  
        this.saveCustomData("isAccountSelected", true);
        this.saveCustomData("selectedAccount", userInfo.partnerProfile);
        setScopeHeader(userInfo.partnerProfile.roles[0]);
      } 
      // case when customer profile
      else if(userInfo?.customerProfiles && userInfo.customerProfiles[0]) {
        store.dispatch("setIsAccountSelected", true);
        store.dispatch("setSelectedAccount", userInfo.customerProfiles[0])
  
        this.saveCustomData("isAccountSelected", true);
        this.saveCustomData("selectedAccount", userInfo.customerProfiles[0]);
        setScopeHeader(userInfo.customerProfiles[0].roles[0]);
      }
    }

    this.saveCustomData("hasAuthenticationErrors", false);
    this.saveCustomData("userInfo", userInfo);
    store.dispatch("setUserInfo", userInfo);
  }

  /**
   * setup custom property in cached profile.
   */
  public saveCustomData(key: string, data: any) {
    const field = key as keyof CustomAccount;

    this.data.custom[field] = data;
    this.storeCustomData()
  }
  private storeCustomData() {
    if (!_.isEmpty(this.data.custom)) {
      this.browserStorage.setItem('msal.custom', JSON.stringify(this.data.custom));
    } else {
      this.browserStorage.removeItem('msal.custom');
    }
  }
  private getStoredCustomData() {
    let customData: CustomAccount;
    const customDataStr = this.browserStorage.getItem('msal.custom');  
    if (customDataStr) {
      customData = JSON.parse(customDataStr);
    } else{
      customData = {
        isAccountSelected: false,
        hasAccountSelector: false,
        hasAuthenticationErrors: true,
      };
    }
    this.data.custom = customData;
  }

  /**
   * return the current time in Unix time (seconds).
   */
  private nowSeconds(): number {
    // Date.getTime() returns in milliseconds.
    return Math.round(new Date().getTime() / 1000.0);
  }

  /**
   * check the token Unix expirationTime against local time
   */
  private isTokenExpired(expiresOn: string, offset: number): boolean {
    // check for access token expiry
    const expirationSec = Number(expiresOn) || 0;
    const offsetCurrentTimeSec = this.nowSeconds() + offset;

    // If current time + offset is greater than token expiration time, then token is expired.
    return (offsetCurrentTimeSec > expirationSec);
  }

  /**
   * get new or cached token and set it up in the storage
   */
  public async getToken(): Promise<string> {
    // try to get cached token
    const idToken = this.browserStorage.getItem("msal.idtoken");
    const expirationTime = this.browserStorage.getItem("msal.idTokenExpiration");
    const issuerAuthority = this.browserStorage.getItem("msal.authority") || undefined;

    if (idToken && expirationTime && !this.isTokenExpired(expirationTime, this.config.system.tokenRenewalOffsetSeconds)) {
      return idToken
    } else {
      const accounts = this.getAllAccounts();
      
      // get new token or redirect to login
      const token:void | msal.AuthenticationResult = await this.getSilentToken(accounts[0], this.loginRequest.scopes, issuerAuthority)

      if (token && token.idToken) {
        const claims: any = token.idTokenClaims
        const time = claims.exp;

        // setup the cache token
        this.browserStorage.setItem('msal.idtoken', token.idToken);
        this.browserStorage.setItem('msal.idTokenExpiration', time);
  
        return token.idToken
      } else {
        await this.logoutRedirect();
        throw new Error('Token not found');
      }
    }
  }

  /**
   * set the authority used in the authentication process
   */
  public setAuthority(authority: string): void {
    this.browserStorage.setItem("msal.authority", authority)
  }

  /**
   * handle the redirect when there is no account setup
   */
  public async authenticateRedirect(): Promise<msal.AccountInfo[]> {
    await this.handleRedirectPromise();

    const accounts = this.getAllAccounts();
    if (accounts.length === 0) {
      await this.loginRedirect(this.loginRequest);
    }
    return accounts;
  }  

  /**
   * get user accounts
   */
   public async getUserAccount(): Promise<msal.AccountInfo> {
    const accounts = this.getAllAccounts();
    return accounts[0];
  }  
  
}

export { msalInstance, ExtendedConfiguration, ExtendedBrowserAuthOptions, ExtendedPublicClientApplication , CustomAccount };
