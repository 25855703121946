import type { AxiosError } from "axios";
import { apiClient, isCancel } from "@/plugins/axios";
import { msalInstance } from "@/plugins/msalPlugin";
import type { RefundObject } from '../types/order'

export default {  
  async getOrders(queryParams: any) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/orders`, {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response
  },
  async requestRefund(data: RefundObject) {
    const token = await msalInstance.getToken();;
    const response = await apiClient.post(`/orders/${data.orderUuid}/refund`, {
      reason: data.reason,
      comment: data.comment
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
};
