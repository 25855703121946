

























































import Vue from "vue";
export default Vue.extend({
 name: "About",
 data: () => ({
  value: true,
 }),
});
