









import Vue from "vue";
export default Vue.extend({
  name: "PartnerAddOrder",
  data: () => ({
  }),
  computed: {
  },
  async created() {
  },
  methods: {
  },
});
