



























































































import Vue from "vue";
import JsonToCsv from "../JsonToCsv.vue";
import type { Partner } from '../../types/partner'
import SubscriptionService from "../../services/SubscriptionService";

export default Vue.extend({
  name: "ChangePartner",
  components: { JsonToCsv },
  props: {
    selectedSubscriptions: { type: Array, default: () => [] }
  },
  data() {
    return {
      selected: [],
      partner: null as Partner | null,
      nullPartner: false,
      sapId: "",
      productKey: "",
      email: "",
      companyName: "",
      name: "",
      country: "",
      loading: false,
      partnerName: "",
      isSelfService: null,
      errorList: [] as {
        uuid: string;
        error: string;
        email: string;
        productKey: string;
      }[],
      hasErrors: false,
      headersErrors: [
        { text: "Subscription Uuid", value: "uuid" },
        {
          text: "Product Key",
          value: "productKey"
        },
        { text: "Email", value: "email" },
        { text: "Error", value: "error" }
      ],
      selfServiceValues: [
        { text: "all", value: null },
        { text: "true", value: true },
        { text: "false", value: false }
      ],
      productKeySearched: "",
      emailSearched: "",
      oldSapId: "",
      loadSearchPartner: false,
      loadSearchSubscriptions: false,
      loadAssign: false
    };
  },
  methods: {
    async searchPartner() {
      this.loadSearchPartner = true;
      const response = await SubscriptionService.retrievePartner(
        this.sapId,
        true,
        false
      );
      if (response) {
        this.partner = response;
      }
      this.loadSearchPartner = false;
    },
    async transferSelectedSubscriptions() {
      this.loadAssign = true;
      if (this.partner) {
        const response = await SubscriptionService.changeOwner(
          this.partner.uuid,
          this.selectedSubscriptions.map((x: any) => x.uuid)
        );

        if (!response.success) {
          this.errorList = [];
          let filtered = [] as any;
          for (const elem of response.failedSubscriptions) {
            filtered = this.selectedSubscriptions.filter(
              (item: any) => item.uuid === elem.uuid
            );
            this.errorList.push({
              email: filtered[0]?.licenses[0]?.email,
              productKey: filtered[0]?.licenses[0]?.identifier,
              ...elem
            });
          }
          this.hasErrors = true;
        } else{
          this.$analytics.track('ownerChanged', {
            noOfLicenses: this.selectedSubscriptions.length
          })
          this.$store.dispatch("createNotification", {
            type: "success",
            text: this.$t("subscription_owner_changed"),
            hasTimeout: true
          });
          this.$emit("closeDialog");
        }

      }
      this.loadAssign = false;
    }
  }
});
