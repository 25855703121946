var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"theme--dark"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orderCommissions,"loading":_vm.loading,"search":_vm.search,"item-key":"uuid","show-expand":"","custom-filter":_vm.customFilter},on:{"update:items":function($event){_vm.orderCommissions=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(_vm._s(_vm.$t("loading_changes")))]},proxy:true},{key:"item.purchaseDate",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm.moment(item.purchaseDate).format("YYYY-MM-DD HH:mm")))])]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.companyName))]),_c('br'),_c('small',[_vm._v(_vm._s(item.customerName))])]}},{key:"item.serialNumber",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(" "+_vm._s(item.identifier)+" ")]),(item.serialNumber && item.identifier)?_c('small',[_vm._v("/")]):_vm._e(),(item.serialNumber)?_c('small',[_vm._v(" "+_vm._s(item.serialNumber))]):_vm._e()]}},{key:"item.commissionMonth",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(" "+_vm._s(_vm.moment(item.commissionMonth) .set("date", 15) .add(2, "M") .format("YYYY-MM-DD"))+" ")])]}},{key:"item.subscriptionType",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.subscriptionType))])]}},{key:"item.purchasingAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.purchasingAmount)+" "),_c('small',[_vm._v(_vm._s(item.currency ? item.currency.toUpperCase() : ""))])]}},{key:"item.purchasingAmountInPayoutCurrency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.purchasingAmountInPayoutCurrency)+" "),_c('small',[_vm._v(_vm._s(item.payoutCurrency ? item.payoutCurrency.toUpperCase() : ""))])]}},{key:"item.commissionAmountInPayoutCurrency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.commissionAmountInPayoutCurrency)+" "),_c('small',[_vm._v(_vm._s(item.payoutCurrency ? item.payoutCurrency.toUpperCase() : ""))])]}},{key:"item.commissionStatus",fn:function(ref){
var item = ref.item;
return [(item.commissionStatus.toLowerCase() === 'approved')?_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"green"}},[_vm._v(" "+_vm._s(item.commissionStatus)+" ")]):_vm._e(),(item.commissionStatus.toLowerCase() === 'pending')?_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"orange"}},[_vm._v(" "+_vm._s(item.commissionStatus)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":2,"sm":12}},[_c('h2',{staticClass:"px-5 py-4 table-title"},[_vm._v(_vm._s(_vm.$t("commissions")))])]),_c('v-col',{attrs:{"md":8,"sm":12}},[_c('v-text-field',{staticClass:"py-3 kickbacks-table--search-field",attrs:{"solo":"","miwidth":"300","dense":"","hide-details":"","label":_vm.$t('filter_order_commissions')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"py-3 text-right",attrs:{"md":2,"sm":12}},[_c('json-to-csv',{attrs:{"data":_vm.orderCommissionsExport,"csv-title":'order_commission.csv',"outline":true,"delimiter":";","label":"export"}})],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('h2',{staticClass:"pa-5 kickbacks-table--no-data"},[_vm._v(" "+_vm._s(_vm.$t("no_commissions_found"))+" ")])]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-3",attrs:{"colspan":headers.length}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t("model_number"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("unit_price"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("exchange_rate"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("converted_unit_price"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("commission"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("commission_amount_in_payout_currency"))+" ")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(item.materialNumber))]),_c('td',[_vm._v(" "+_vm._s(item.purchasingAmount)+" "),_c('small',[_vm._v(_vm._s(item.currency ? item.currency.toUpperCase() : ""))])]),_c('td',[_vm._v(" "+_vm._s(item.exchangeRate)+" "),_c('small',[_vm._v(_vm._s(item.currency ? item.currency.toUpperCase() : ""))])]),_c('td',[_vm._v(" "+_vm._s(item.purchasingAmountInPayoutCurrency)+" "),_c('small',[_vm._v(_vm._s(item.payoutCurrency ? item.payoutCurrency.toUpperCase() : ""))])]),_c('td',[_vm._v(_vm._s(item.commission)+" %")]),_c('td',[_vm._v(" "+_vm._s(item.commissionAmountInPayoutCurrency)+" "),_c('small',[_vm._v(_vm._s(item.payoutCurrency ? item.payoutCurrency.toUpperCase() : ""))])])])])]},proxy:true}],null,true)})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }