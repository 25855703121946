























import Vue from "vue";
export type ConfirmDialogType = Vue & {
  open: (title: string, message: string, options?: any) => Promise<boolean>;
};
export default Vue.extend({
  name: "ConfirmDialog",
  data: () => ({
    dialog: false,
    resolve: (result: boolean) => {},
    reject: (result: boolean) => {},
    message: "",
    title: "",
    options: {
      color: "error",
      width: 290,
      zIndex: 200
    }
  }),
  methods: {
    open(title: string, message: string, options: any) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
});
