



















































































































































































































































































































import Vue from "vue";
import CustomerService from "../../services/CustomerService";

export default Vue.extend({
  name: "CustomerOrders",
  data() {
    return {
      searchModel: "",
      dialog: false,
      loading: false,
      model: [] as string[],
      itemsPerPage: 5,
      loadRefundButton: false,
      reason: "",
      comment: "",
      search: "",
      selected: [],
      actions: [
        { text: "Request refund", icon: "mdi-open-in-new" },
        { text: "Download", icon: "mdi-download" }
      ],
      mainActions: [
        { text: "Export selected", icon: "mdi-file-export" },
        { text: "Download selected", icon: "mdi-download" }
      ],
      invoices: [] as any
    };
  },
  computed: {
    selectedAccount(): any {
      return this.$store.getters.selectedAccount;
    },
    headers(): {
      value: string;
      text: string;
      sortable?: boolean;
    }[] {
      return [
        {
          text: this.$t("order_no"),
          value: "foreignOrderId"
        },
        { text: this.$t("issued_at"), value: "purchasedAt" },
        { text: this.$t("amount"), value: "total" },
        { text: this.$t("currency"), value: "currency" },
        { text: this.$t("status"), value: "status" },
        { text: this.$t("actions"), value: "actions", sortable: false }
      ];
    },
    reasonItems(): {
      value: string;
      text: string;
    }[] {
      return [
        {
          text: this.$t("product_not_received"),
          value: "product_not_received"
        },
        { text: this.$t("incorrect_product"), value: "incorrect_product" },
        {
          text: this.$t("product_not_matching"),
          value: "product_not_matching"
        },
        { text: this.$t("product_not_working"), value: "product_not_working" },
        { text: this.$t("unwanted_renewal"), value: "unwanted_renewal" },
        { text: this.$t("duplicate_order"), value: "duplicate_order" },
        { text: this.$t("other"), value: "other" }
      ];
    }
  },
  async created() {
    await this.getInvoicesData();
  },
  methods: {
    allowRefunds(item: {
      purchasedAt: string;
      status: string;
      refundStatus: number;
    }) {
      // todo: add rule for purchase date
      return (
        item.status.toLocaleLowerCase() === "complete" &&
        item.refundStatus === 0 &&
        this.$moment(item.purchasedAt)
          .add(14, "days")
          .toDate() > this.$moment().toDate()
      );
    },
    customFilter(value: string, search: string, item: any) {
      const {
        purchasedAt,
        currency,
        foreignOrderId,
        orderSubscriptions,
        status,
        total,
        updatedAt
      } = item;

      if (item) {
        const hasCurrency = currency
          ? currency.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          : false;
        const hasPurchasedAt = purchasedAt
          ? purchasedAt.toString().includes(search)
          : false;
        const hasUpdatedAt = updatedAt
          ? updatedAt.toString().includes(search)
          : false;
        const hasForeignOrderId = foreignOrderId
          ? foreignOrderId.includes(search)
          : false;
        const hasStatus = status
          ? status.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          : false;
        const hasTotal = total ? total.toString().includes(search) : false;
        let hasEmail = false;
        let hasPK = false;
        let hasProductName = false;
        let hasProductCode = false;
        let hasSubscriptionType = false;
        for (let subscription of orderSubscriptions) {
          hasEmail =
            subscription.licenseEmail && !hasEmail
              ? subscription.licenseEmail
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              : false;
          hasPK =
            subscription.licenseIdentifier && !hasPK
              ? subscription.licenseIdentifier
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              : false;
          hasProductName =
            subscription.planName && !hasProductName
              ? subscription.planName
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              : false;
          hasProductCode =
            subscription.planCode && !hasProductCode
              ? subscription.planCode
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              : false;
          hasSubscriptionType =
            subscription.subscriptionType && !hasSubscriptionType
              ? subscription.subscriptionType
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              : false;
        }
        return (
          hasCurrency ||
          hasPurchasedAt ||
          hasForeignOrderId ||
          hasUpdatedAt ||
          hasStatus ||
          hasTotal ||
          hasEmail ||
          hasPK ||
          hasProductName ||
          hasProductCode ||
          hasSubscriptionType
        );
      }
      return true;
    },
    async getInvoicesData() {
      this.loading = true;
      let response = await CustomerService.getOrdersForCustomer(
        this.$store.getters.selectedAccount.customerUuid
      );
      this.invoices = response;
      this.loading = false;
      return;
    },

    convertBase64ToBlob(base64Image: string) {
      // Split into two parts
      const parts = base64Image.split(";base64,");

      // Hold the content type
      const contentType = parts[0].split(":")[1];

      // Decode Base64 string
      const decodedData = window.atob(parts[1]);

      // Create UNIT8ARRAY of size same as row data length
      const uInt8Array = new Uint8Array(decodedData.length);

      // Insert all character code into uInt8Array
      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
      }

      // Return BLOB image after conversion
      return new Blob([uInt8Array], { type: contentType });
    },
    async downloadInvoice(orderId: string) {
      const customerUuid = this.selectedAccount.customerUuid;
      let response = await CustomerService.getInvoiceForOrder(
        customerUuid,
        orderId
      );
      if (response) {
        const url = window.URL.createObjectURL(
          this.convertBase64ToBlob(response.data)
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
    async downloadRefundInvoice(orderId: string) {
      const customerUuid = this.selectedAccount.customerUuid;
      let response = await CustomerService.getInvoiceForRefundOrder(
        customerUuid,
        orderId
      );
      if (response) {
        const url = window.URL.createObjectURL(
          this.convertBase64ToBlob(response.data)
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
    async requestRefund(orderUuid: string) {
      this.loadRefundButton = true;
      const customerUuid = this.selectedAccount.customerUuid;

      const response = await CustomerService.sendRefundRequest({
        customerUuid: customerUuid,
        orderUuid: orderUuid,
        reason: this.reason,
        comment: this.comment
      });

      if (response) {
        this.getInvoicesData();
      }
      this.reason = "";
      this.comment = "";
      this.dialog = false;
      this.loadRefundButton = false;
    }
  }
});
