import CountriesService from "../../services/CountryService";
import CustomerService from "../../services/CustomerService";
import { CountryDetails, ProvincesDetails, SpeechLiveCountry } from "../../types/country";
import { CustomerDetails, AddressDetails } from "../../types/customer";
import store from "@/store/index";

const state = {
  countriesList: [] as CountryDetails[],
  provincesList: [] as ProvincesDetails[],
  customerData: {} as CustomerDetails,
  newAddress: {} as AddressDetails,
  savedAddress: {} as AddressDetails,
  customerUUID: "" as string,
  speechLiveCountries: {} as SpeechLiveCountry[]
};

const getters = {
  customerUUID: (state: any) => state.customerUUID,

};

const mutations = {
  SET_DATA(state: any, countries: CountryDetails[]) {
    state.countriesList = countries;
  },
  SET_PROVINCES(state: any, provinces: ProvincesDetails[]) {
    state.provincesList = provinces;
  },
  SET_CUSTOMER_DATA(state: any, data: any) {
    state.customerData = data;
  },
  SET_NEW_ADDRESS(state: any, address: AddressDetails) {
    state.newAddress = address;
  },
  SET_CUSTOMER_UUID(state: any, id: string) {

    state.customerUUID = id;
  },
  SET_SPEECHLIVE_COUNTRIES(state: any, data: SpeechLiveCountry) {
    state.speechLiveCountries = data;
  }
};

const actions = {
  async fetchCustomerData(context: any) {
    const response = await CustomerService.fetchCustomerData();
    if (response) {
      context.commit("SET_CUSTOMER_DATA", response);
    } else {
      context.commit("SET_CUSTOMER_DATA", []);
    }
  },
  /* setSelectedCustomer(context: any, customerUUID: string) {
    context.commit("SET_CUSTOMER_UUID", customerUUID)
  },
  async fetchCountries(context: any) {
    try {
      const response = await CountriesService.fetchCountries();
      const countries = [] as CountryDetails[];
      response.data.forEach((element: CountryDetails) => {
        if (element.hasProvinces) {
          context.dispatch("fetchProvinces", element.code).then(() => {
            countries.push({
              code: element.code,
              name: element.name,
              currency: element.currency,
              region: element.region,
              hasProvinces: element.hasProvinces,
              provincesList: state.provincesList
            });
          });
        } else {
          countries.push({
            code: element.code,
            name: element.name,
            currency: element.currency,
            region: element.region,
            hasProvinces: element.hasProvinces,
            provincesList: []
          });
        }
      });
      context.commit("SET_DATA", countries);
    } catch (error) {
      context.commit("SET_NOTIFICATION", error);
      context.commit("SET_DATA", []);
    }
  },
  async fetchSpeechLiveCountries(context: any) {
    try {
      const response = await CustomerService.fetchSpeechLiveCountries();
      let countries = response.data.data
      context.commit("SET_SPEECHLIVE_COUNTRIES", countries);
    } catch (error) {
      context.commit("SET_NOTIFICATION", error);
      context.commit("SET_SPEECHLIVE_COUNTRIES", []);
    }
  },
  async fetchProvinces(context: any, country: string) {
    try {
      const response = await CountriesService.fetchProvinces(country);
      context.commit("SET_PROVINCES", response.data);
    } catch (error) {
      context.commit("SET_NOTIFICATION", error);
      context.commit("SET_PROVINCES", []);
    }
  },
  async confirmCustomer(context: any, customerUUID: string) {
    try {
      const response = await CustomerService.successRegistration(customerUUID);
      return response
    } catch (error) {
      return error
    }
  },

  async updateAddress(
    context: any,
    data: { addressId: string; data: AddressDetails }
  ) {
    try {
      const response = await CustomerService.updateAddress(
        data.addressId,
        data.data
      );
      return response;
    } catch (error) {
      store.dispatch("createNotification", {
        type: "error",
        text: error.message,
        refresh: true
      });
      return error;
    }
  },
  async createAddress(context: any, data: AddressDetails) {
    try {
      const response = await CustomerService.createAddress(data);
      return response;
    } catch (error) {
      store.dispatch("createNotification", {
        type: "error",
        text: error.message,
        refresh: true
      });
      return error;
    }
  }, */
};


export default {
  state,
  getters,
  actions,
  mutations
};