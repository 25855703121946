






























import Vue from "vue";
import PartnerSubscriptions from "../components/PartnerSubscriptionsList.vue";
import TransferSubscriptions from "../views/TransferSubscriptions.vue";
import { filteredPartnerSubscriptionsNavigationItems } from "../permissions/navigation-permission";
export default Vue.extend({
  name: "PersonalSettings",
  components: {
    PartnerSubscriptions,
    TransferSubscriptions
  },
  computed: {
    selectedAccount(): any {
      return this.$store.getters.selectedAccount;
    }
  },
  watch: {
    tab() {
      switch (this.tab) {
      }
    }
  },
  async created() {
    await this.setNavigationItems();
  },
  data: () => ({
    navItems: [] as any[],
    tab: null
  }),
  methods: {
    async setNavigationItems() {
      this.navItems = [];

      /* All roles for testing */
      if (this.selectedAccount) {
        this.navItems = filteredPartnerSubscriptionsNavigationItems;
      }
    }
  }
});
