import axios from "axios";

export default {
  async getIpInformationMain() {
    try {
      return await axios.get(`https://ipapi.co/json/`);
    } catch (e) {
      /* We need custom error handling here */
      throw e;
    }
  },
  async getIpInformationFallback() {
    try {
      return await axios.get(`https://json.geoiplookup.io/api`);
    } catch (e) {
      /* We need custom error handling here */
      throw e;
    }
  }
};
