import type { VueI18NextComponentOptions } from "@panter/vue-i18next";
import { AxiosInstance } from "axios";
import VueRouter, { Route } from 'vue-router';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18next";
import { apiClient } from "./plugins/axios";
import msalPlugin from "./plugins/msalPlugin";
import { msalConfig } from "./authConfig";
import {ExtendedPublicClientApplication}  from "./plugins/msalPlugin";
import getEnv from "./utils/env";
import AnalyticsVuePlugin from "./plugins/analytics";

Vue.use(AnalyticsVuePlugin, {
  baseName: 'SpeechID',
  instrumentationKey: getEnv('VUE_APP_INSIGHTS_INSTRUMENTATION_KEY'),
  router,
  trackInitialPageView: true
});
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import "ui-sandbox/sps-components.css";
declare module 'vue/types/vue' {
  interface Vue {
    $axios: AxiosInstance;
    $router: VueRouter
    $route: Route
    $msal: ExtendedPublicClientApplication;
    $i18next: VueI18NextComponentOptions;
  }
}
//Vue.use(VueTelInput as any, {});
Vue.use(msalPlugin, msalConfig);
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.config.productionTip = false;
Vue.prototype.$axios = apiClient;
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
