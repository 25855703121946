var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.organizationMembers,"headers":_vm.headers,"items-per-page":10,"search":_vm.search,"loading":_vm.loading,"single-expand":false,"item-key":"uuid"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('h2',{staticClass:"px-5 py-4 table-title"},[_vm._v(" "+_vm._s(_vm.$t("employees"))+" ")]),_c('v-text-field',{staticClass:"mt-3",attrs:{"outlined":"","solo":"","label":_vm.$t('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-3 mx-3",attrs:{"color":"success","outlined":"","fab":"","disabled":_vm.disableActions,"small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("new_employee")))])],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-text-field',{staticClass:"pt-2",attrs:{"outlined":"","solo":"","rules":[_vm.rules.required],"label":_vm.$t('first_name')},model:{value:(_vm.newMember.firstName),callback:function ($$v) {_vm.$set(_vm.newMember, "firstName", $$v)},expression:"newMember.firstName"}}),_c('v-text-field',{staticClass:"pt-2",attrs:{"outlined":"","solo":"","rules":[_vm.rules.required],"label":_vm.$t('last_name')},model:{value:(_vm.newMember.lastName),callback:function ($$v) {_vm.$set(_vm.newMember, "lastName", $$v)},expression:"newMember.lastName"}}),_c('v-text-field',{staticClass:"pt-2",attrs:{"outlined":"","solo":"","rules":[_vm.rules.required, _vm.rules.email],"label":_vm.$t('email')},model:{value:(_vm.newMember.eMail),callback:function ($$v) {_vm.$set(_vm.newMember, "eMail", $$v)},expression:"newMember.eMail"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"gray","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary darken-1","text":"","disabled":!_vm.isFormValid},on:{"click":_vm.createOrganizationMember}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(
          item._links && item._links['contact-management:members:inactivate']
        )?_c('v-btn',{attrs:{"icon":"","disabled":_vm.disableActions},on:{"click":function($event){return _vm.deleteOrganizationMember(item.uuid)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}],null,true)}),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }