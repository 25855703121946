import type { AxiosError, AxiosInstance } from "axios";
import store from '@/store';
import { apiClient } from "@/plugins/axios";
import { msalInstance } from "@/plugins/msalPlugin";

interface TransferSubscriptionObject {
  productKey: string;
  serialNumber: string;
  partnerId: string;
}
interface RefundObject {
  partnerUuid: string;
  orderUuid: string;
  reason: string;
  comment: string;
}


export default {
  async sendSubscriptionsToValidateTransfer(file: any) {
    const token = await msalInstance.getToken();;
    const formData = new FormData();
    formData.append('file', file, 'subscription-transfer-file.csv');
    const response = await apiClient.post("/subscriptions/validate-bulk-transfer", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    }).then((responseApi: any) => {
      return responseApi.data.data;
    }).catch(async (error: AxiosError) => {
      if (error.message.includes("400")) {
        store.dispatch("createNotification", {
          type: "error",
          text: error.message + ": there were some errors parsing your file. Please make sure the delimiter in your file is a ';'",
          hasTimeout: true,
          timeout: 10000,
        })
      } else {
        await error?.globalHandler();
      }
    })
    return response;
  },
  async sendSubscriptionsToTransfer(data: TransferSubscriptionObject[]) {
    const token = await msalInstance.getToken();
    const response = await apiClient.post("/subscriptions/bulk-transfer", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async getSubpartnerList(partnerUuid: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/partners/${partnerUuid}/sub-partners`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async getOrderCommissionsList(partnerUuid: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/partners/${partnerUuid}/commissions/orders`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    }).then((responseApi: any) => {
      return responseApi.data.orderCommissions;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async fetchPartnerSupportData(partnerUuid: string) {
    const token = await msalInstance.getToken();;
    const response = await apiClient.get(`/partners/${partnerUuid}/support`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(responseApi => {
      return responseApi;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async updatePartnerSupportData(partnerUuid: string, partnerSupportData: any) {
    const token = await msalInstance.getToken();;
    const response = await apiClient.post(`/partners/${partnerUuid}/support`, partnerSupportData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(responseApi => {
      return responseApi;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async getOrders(partnerUuid: string, queryParams: any) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/partners/${partnerUuid}/orders`, {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response
  },
  async blockSubpartner(partnerUuid: string, subPartnerUuid: any) {
    const token = await msalInstance.getToken();
    const response = await apiClient.post(`/partners/${partnerUuid}/sub-partners/${subPartnerUuid}/block`,{}, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response
  },
  async unblockSubpartner(partnerUuid: string, subPartnerUuid: any) {
    const token = await msalInstance.getToken();
    const response = await apiClient.post(`/partners/${partnerUuid}/sub-partners/${subPartnerUuid}/unblock`,{}, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response
  },
  async getOrganizationMembers(partnerUuid: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/partners/${partnerUuid}/members`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async deleteOrganizationMember(partnerUuid: string, organizationMemberUuid: any) {
    const token = await msalInstance.getToken();
    const response = await apiClient.delete(`/partners/${partnerUuid}/members/${organizationMemberUuid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    }).then((responseApi: any) => {
      
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response
  },
  async createOrganizationMember(partnerUuid: string, data: any) {
    const token = await msalInstance.getToken();
    const response = await apiClient.post(`/partners/${partnerUuid}/members`,data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response
  },
  async editSubscriptionComment(partnerUuid:string, subscriptionUuid: string, comment: any) {
    const token = await msalInstance.getToken();
    const response = await apiClient.patch(`partners/${partnerUuid}/subscriptions/${subscriptionUuid}`,{
      partnerComment: comment
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response
  },
  async requestRefund(data: RefundObject) {
    const token = await msalInstance.getToken();
    const response = await apiClient.post(`/partners/${data.partnerUuid}/orders/${data.orderUuid}/refund`, {
      reason: data.reason,
      comment: data.comment
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async activatePartnerUser(){
    const token = await msalInstance.getToken();
    
    const response = await apiClient.post(`/partners/members/activate`,{}, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async setCancellationReason(partnerUuid:string, subscriptionUuid: string, reason: string) {
    const token = await msalInstance.getToken();
    const response = await apiClient.patch(`partners/${partnerUuid}/subscriptions/${subscriptionUuid}`,{
      cancellationReason: reason
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response
  },
  async getSubscriptionsCount(partnerUuid: string, params:{
    isExpired: boolean,
    cancellationReason:string
  }) {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/partners/${partnerUuid}/subscriptions/count?isExpired=${params.isExpired}&cancellationReason=${params.cancellationReason}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data.count;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
};
