






























import Vue from "vue";
import Information from "../components/_personal-settings/Information.vue";
import Security from "../components/_personal-settings/Security.vue";
import About from "../components/_personal-settings/About.vue";

export default Vue.extend({
  name: "PersonalSettings",
  components: {
    Information,
    Security,
    About
  },

  watch: {
    tab() {
      switch (this.tab) {
      }
    }
  },
  data: () => ({
    tab: null,
    menu: [
      {
        title: "information",
        icon: "mdi-badge-account-horizontal",
        component: "Information"
      },
      /* {
        title: "security",
        icon: "mdi-lock",
        component: "Security",
      },*/
      {
        title: "about",
        icon: "mdi-information",
        component: "About"
      }
    ]
  })
});
