
































import Vue from "vue";
import { mapActions } from "vuex";
import store from "../store/index";
import { setScopeHeader } from "../plugins/axios";
export default Vue.extend({
  name: "AccountSelectorCard",
  props: {
    companyDetail: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      selectedCustomerUuid: ""
    };
  },
  computed: {},

  methods: {
    setProfile() {
      this.$store.dispatch("setIsAccountSelected", true);
      this.$store.dispatch("setSelectedAccount", this.companyDetail);

      this.$msal.saveCustomData("isAccountSelected", true);
      this.$msal.saveCustomData("selectedAccount", this.companyDetail);

      const scopeSelected = this.companyDetail.roles[0];
      setScopeHeader(scopeSelected);
    }
  }
});
