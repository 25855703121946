













































import Vue from "vue";
import PartnerService from "../services/PartnerService";
export default Vue.extend({
  name: "SubpartnerList",
  data: () => ({
    subpartnerList: [],
    headers: [] as {
      text: string;
      value: string;
      filter?: any;
      sortable?: boolean;
      align?: string;
      width?: string;
    }[],
    search: "",
    loading: false
  }),
  computed: {},
  created() {
    this.headers = [
      { text: this.$t("partner_name"), value: "name" },
      { text: this.$t("sap_id"), value: "sapId" },
      { text: this.$t("country"), value: "country" },
      { text: this.$t("language"), value: "language" },
      { text: this.$t("actions"), value: "actions", sortable: false }
    ];
    this.getSubpartnerList();
  },
  methods: {
    async getSubpartnerList() {
      this.loading = true;
      const partnerUuid = this.$store.getters.selectedAccount.partner.uuid;
      let response = await PartnerService.getSubpartnerList(partnerUuid);
      if (response) {
        this.subpartnerList = response;
      }
      this.loading = false;
    },
    async blockSubpartner(subpartnerUuid: string) {
      const partnerUuid = this.$store.getters.selectedAccount.partner.uuid;
      await PartnerService.blockSubpartner(partnerUuid, subpartnerUuid);
      await this.getSubpartnerList();
    },
    async unblockSubpartner(subpartnerUuid: string) {
      const partnerUuid = this.$store.getters.selectedAccount.partner.uuid;
      await PartnerService.unblockSubpartner(partnerUuid, subpartnerUuid);
      await this.getSubpartnerList();
    }
  }
});
