import type { AxiosError } from "axios";
import axios from 'axios';
import getEnv from "@/utils/env";
import store from '@/store';
import router from '@/router';
import { msalInstance } from "./msalPlugin";

declare module 'axios' {
  interface AxiosError {
    // eslint-disable-next-line @typescript-eslint/ban-types
    globalHandler: Function;
  }
}

const parseError = (error: AxiosError) => {
  let errorText;
  try {
    const errorObject = error?.response?.data?.errors;

    if (typeof errorObject === 'object') {
      errorText = errorObject[Object.keys(errorObject)[0]][0];
    } else {
      errorText = errorObject;
    }
  } catch {
    errorText = 'Failed to parse error response'
  }
  return errorText;
};

const apiClient = axios.create({
  baseURL: getEnv("VUE_APP_ROOT_API").toString() + getEnv("VUE_APP_ROOT_API_VERSION").toString(),
  withCredentials: true,
});

// Attach headers before the request goes out
const setScopeHeader = (scope: string) => {
  apiClient.defaults.headers.common['X-App-Scope'] = `${scope}`;
}

const errorHandler = (error: AxiosError) => {
  return async () => {
    store.dispatch("setAuthenticationErrors", true);

    /* Display network error */
    if (error.message.includes("Network Error")) {
      store.dispatch("createNotification", {
        type: "error",
        text: error.message + ": Please check your connectivity and try again.",
        hasTimeout: true,
        timeout: 10000,
        refresh: true
      })
    }

    /* Handle unhandled bad request errors  */
    else if (error.message.includes("400")) {
      store.dispatch("createNotification", {
        type: "error",
        text: parseError(error),
        hasTimeout: false,
        logout: false
      })
    }

    /* Log out user if they are not authenticated */
    else if (error.message.includes("401")) {
      // Disable user logout for now
      // AuthenticationService.slLinksDelete();
      // await msal.$msal.signOut();
  
      store.dispatch("createNotification", {
        type: "error",
        text: "You do not have authorization to access this resource.",
        hasTimeout: false,
        logout: false
      })
    }

    /* Obfuscate user that page does not exist */
    else if (error.message.includes("403")) {
      router.push('/404')
    }

    /* Obfuscate user that page does not exist */
    else if (error.message.includes("403")) {
      router.push('/404')
    }

    /* Handle unhandled conflict errors  */
    else if (error.message.includes("409")) {
      store.dispatch("createNotification", {
        type: "error",
        text: parseError(error),
        hasTimeout: false,
        logout: false
      })
    }

    /* Internal server error */
    else if (error.message.includes("500")) {
      store.dispatch("createNotification", {
        type: "error",
        text: "An internal server error occured. Please try again later.",
        hasTimeout: false,
        logout: false
      })
    }

    /* Not found */
    else if (error.message.includes("404")) {
      store.dispatch("createNotification", {
        type: "error",
        text: parseError(error),
        hasTimeout: true,
        logout: false
      })
    }

    /* Unknown error */
    else {
      store.dispatch("createNotification", {
        type: "error",
        text: error.message,
        timeout: 10000,
        logout: false
      })
    }
  }
}

const isCancel = (error: AxiosError) => {
  return axios.isCancel(error)
}
// Response interceptors. 
apiClient.interceptors.response.use(
  response => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  async (error: AxiosError) => {
    error.globalHandler = errorHandler(error);

    return Promise.reject(error);
  }
);

export { setScopeHeader, isCancel, apiClient };
