


















































































































import Vue from "vue";
import { mapState } from "vuex";
import store from "@/store/index";
import { CountryDetails, ProvincesDetails } from "@/types/country";
import { AddressDetails, CustomerStatus } from "@/types/customer";

export default Vue.extend({
  name: "AddressEdit",
  props: {
    addressData: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hasProvince: false,
      provincesList: {}
    };
  },
  computed: {
    ...mapState(["customer"]),
    selectedCountry(): string {
      return this.addressData.country;
    }
  },
  created() {
    this.setProvince();
  },
  watch: {
    selectedCountry() {
      this.hasProvince = false;
      this.addressData.provinceOrState = "";
      this.getProvincesList(this.selectedCountry);
    }
  },
  methods: {
    setProvince() {
      if (this.addressData.provinceOrState) {
        this.hasProvince = true;
        this.getProvincesList(this.selectedCountry);
      } else {
        this.hasProvince = false;
      }
    },
    getProvincesList(country: string) {
      this.customer.countriesList.forEach((element: CountryDetails) => {
        if (element.name === country && element.hasProvinces) {
          this.hasProvince = true;
          this.provincesList = element.provincesList;
        }
      });
    }
  }
});
