














import Vue from "vue";

export default Vue.extend({
  name: "LanguageSwitcher",
  props: {
    dark: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      lang: "",
      locales: [
        { value: "en", text: "English" },
        { value: "de", text: "Deutsch" },
        { value: "fr", text: "Français" }
      ]
    };
  },
  created() {
    // TODO: add with priority the language from the settings
    let detectedLanguage = this.detectLanguage();
    switch (detectedLanguage) {
      case "de": {
        this.lang = "de";
        break;
      }
      case "fr": {
        this.lang = "fr";
        break;
      }
      default: {
        this.lang = "en";
        break;
      }
    }
    this.changeLanguage(this.lang);
  },
  methods: {
    changeLanguage(lang: string) {
      this.$i18n.i18next.changeLanguage(lang);
    },
    detectLanguage() {
      let detectedLanguage =
        this.$i18n.i18next.services.languageDetector.detect() || "en";
      if (detectedLanguage.includes("-")) {
        detectedLanguage = detectedLanguage.split("-")[0];
      }
      return detectedLanguage;
    }
  }
});
