



















import Vue from "vue";
import PartnerService from "../services/PartnerService";
import DictationLogo from "../components/_logos/PhilipsDictation.vue";

export default Vue.extend({
  name: "ActivateAccount",
  components: {
    DictationLogo
  },
  data: () => {
    return {
      loading: true
    };
  },
  async created() {
    const token = await this.setAuthentication();
    await this.activatePartnerUser(token);
  },
  methods: {
    async setAuthentication(): Promise<string> {
      try {
        const response = await this.$msal.handleRedirectPromise();

        if (response) {
          this.$msal.setAuthority(response?.authority);
          return response.idToken;
        } else {
          throw new Error("Missing token");
        }
      } catch (error) {
        this.loading = false;
        throw error;
      }
    },
    async activatePartnerUser(token: string): Promise<void> {
      try {
        await PartnerService.activatePartnerUser();
        //await this.$msal.authenticateNewPolicy();
        this.$router.push("/");
      } catch (error) {
        this.loading = false;
        throw error;
      }
    },
    logout() {
      this.$msal.logout();
    }
  }
});
