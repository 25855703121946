











































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import PartnerService from "../services/PartnerService";
interface SupportContactData {
  id: number;
  organizationId: number;
  organizationUuid: string;
  website: string;
  country: string;
  email: string;
  phone: string;
  supportEmail: string;
  supportPhone: string;
  deleted: boolean;
}
export default Vue.extend({
  name: "PartnerSupport",
  data: () => ({
    loading: false,
    editable: false,
    loadingData: false,
    supportData: {} as SupportContactData
  }),
  computed: {
    ...mapState(["customer"])
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loadingData = true;
      const result = await PartnerService.fetchPartnerSupportData(
        this.$store.getters.selectedAccount?.partner?.uuid
      );
      if (result) {
        this.supportData = result?.data;
      }
      this.loadingData = false;
    },
    async updateData() {
      this.loading = true;
      const result = await PartnerService.updatePartnerSupportData(
        this.$store.getters.selectedAccount?.partner?.uuid,
        this.supportData
      );
      if (result?.status === 200) {
        this.$store.dispatch("createNotification", {
          type: "success",
          text: this.$t("partner_support_information_updated"),
          hasTimeout: true,
          timeout: 5000
        });
      }
      this.loading = false;
      this.editable = false;
    }
  }
});
