import type { AxiosError, AxiosInstance } from "axios";
import { apiClient, isCancel } from "@/plugins/axios";
import { msalInstance } from "@/plugins/msalPlugin";
import type { Partner } from '../types/partner'
import { SpeechProfileTransfer } from "@/types/customer";

export default {
  async fetchData(customerUuid: string) {
    const token = await msalInstance.getToken();;

    const response = await apiClient.get(`/customers/${customerUuid}/subscriptions`, {
      headers: { authorization: "Bearer " + token },
    }).then((responseApi: any) => {
      return responseApi.data.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  /* async updateCreditCardTrial(data: {
    data: object;
    subscriptionUuid: string;
  }) {
    const customerUUID = msal.$msal.data.custom.selectedCustomerUuid;
    if (!customerUUID) {
      router.push("account-selector");
    }

    const token = await msalInstance.getToken();;
    if (!token) {
      msal.$msal.signIn();
    }

    return await apiClient.post(
      `/customers/${customerUUID}/subscriptions/${data.subscriptionUuid}/purchase`,
      data.data,
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    );
  }, */
  /*   async getPriceDetails(data: { data: object; subscriptionUuid: string }) {
      const customerUUID = msal.$msal.data.custom.selectedCustomerUuid;
      if (!customerUUID) {
        router.push("account-selector");
      }
  
      const token = await msalInstance.getToken();;
      if (!token) {
        msal.$msal.signIn();
      }
  
      return await apiClient.post(
        `/customers/${customerUUID}/subscriptions/${data.subscriptionUuid}/compute-change`,
        data.data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },  
  */
  /**
   * 
   * @param partnerUuid Partner unique identifier
   * @param queryParams Query params for get query
   * @returns 
   */
  async getPartnerSubscriptions(partnerUuid: string, queryParams: any, abortController?: AbortController) {
    const token = await msalInstance.getToken();

    const response = await apiClient.get(`/partners/${partnerUuid}/subscriptions`, {
      params: queryParams,
      headers: { authorization: "Bearer " + token },
      signal: abortController? abortController.signal: undefined
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {

      if (!isCancel(error)) {
        
        await error?.globalHandler();
      } 
    })
    return response;
  },
  async getSubscriptions(params: any, abortController?: AbortController) {
    const token = await msalInstance.getToken();

    const response = await apiClient.get(`/subscriptions`, {
      params,
      headers: { authorization: "Bearer " + token },
      signal: abortController? abortController.signal: undefined
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      if (!isCancel(error)) {
        await error?.globalHandler();
      } 
    })
    return response;
  },
  async retrievePartner(sapId: string, isEligibleToCommissions: boolean, convertToUnassisted?: boolean): Promise<Partner> {
    const token = await msalInstance.getToken();
    const response = await apiClient.get(`/partners/search`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      params: {
        sapId: sapId,
        isEligibleToCommissions: isEligibleToCommissions,
        convertToUnassisted: convertToUnassisted,
      }
    }).then((responseApi: any) => {
      return responseApi.data.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async assignPartner(organizationUuid: string, subscriptionsUuids: string[]): Promise<void> {
    const token = await msalInstance.getToken();
    const data = {
      organizationUuid: organizationUuid,
      subscriptionsUuids: subscriptionsUuids
    };
    const response = await apiClient.post(`/subscriptions/assign-partner`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async changeOwner(organizationUuid: string, subscriptionsUuids: string[]): Promise<{
    success: false,
    failedSubscriptions: {
      uuid: string,
      error: string
    }[]
  }> {
    const token = await msalInstance.getToken();
    const data = {
      organizationUuid: organizationUuid,
      subscriptionsUuids: subscriptionsUuids
    };
    const response = await apiClient.post(`/subscriptions/change-owner`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
  async transferSpeechExecSubscription(data: SpeechProfileTransfer) {
    const token = await msalInstance.getToken();
    const response = await apiClient.post(`/subscriptions/${data.subscriptionUuid}/customers/${data.oldCustomerUuid}/transfer`, {
      customerUuid: data.customerUuid,
      customerEmail: data.customerEmail,
      productKey: data.productKey,
      oldCustomerEmail: data.oldCustomerEmail,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((responseApi: any) => {
      return responseApi.data;
    }).catch(async (error: AxiosError) => {
      await error?.globalHandler();
    })
    return response;
  },
};
